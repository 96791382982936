import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Container } from 'react-bootstrap';
import { doc, getDoc, deleteDoc, updateDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '../config/firebase-config';
import AddClassModal from '../AddClassModal';
import { Calendar, CreditCard } from 'react-bootstrap-icons';
import '../styles/CatalogItemDetail.css';

const formatDuration = (minutes) => {
  if (!minutes) return '';
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  
  if (hours === 0) {
    return `${remainingMinutes}min`;
  } else if (remainingMinutes === 0) {
    return `${hours}h`;
  } else {
    return `${hours}h ${remainingMinutes}min`;
  }
};

const CatalogItemDetail = () => {
  const { itemId } = useParams();
  const navigate = useNavigate();
  const [item, setItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const auth = getAuth();

  useEffect(() => {
    const fetchItem = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          navigate('/login');
          return;
        }

        const itemDoc = await getDoc(doc(db, 'classes', itemId));
        
        if (!itemDoc.exists()) {
          setError('Item not found');
          return;
        }

        const itemData = itemDoc.data();
        
        if (itemData.createdBy !== user.uid) {
          setError('Unauthorized access');
          return;
        }

        setItem({
          id: itemDoc.id,
          ...itemData
        });
      } catch (error) {
        console.error('Error fetching item:', error);
        setError('Error loading item details');
      } finally {
        setLoading(false);
      }
    };

    fetchItem();
  }, [itemId, navigate, auth]);

  const handleDelete = async () => {
    if (window.confirm('¿Estás seguro de que quieres eliminar este elemento?')) {
      try {
        await deleteDoc(doc(db, 'classes', itemId));
        navigate(-1);
      } catch (error) {
        console.error('Error deleting item:', error);
        setError('Error deleting item');
      }
    }
  };

  const handleEditSave = async (updatedItemData) => {
    try {
      await updateDoc(doc(db, 'classes', itemId), updatedItemData);
      const newItemDoc = await getDoc(doc(db, 'classes', itemId));
      setItem({
        id: newItemDoc.id,
        ...newItemDoc.data()
      });
    } catch (error) {
      console.error('Error updating item:', error);
      setError('Error updating item');
    }
    setShowEditModal(false);
  };

  if (loading) return <div>Cargando...</div>;
  if (error) return <div>{error}</div>;
  if (!item) return <div>Item no encontrado</div>;

  const renderItemDetails = () => {
    switch(item.inventoryType) {
      case 'service':
        return (
          <>
            <div className="reservation-info">
              {item.isOnlineReservation && (
                <div className="info-item">
                  <Calendar size={20} />
                  <span>Disponible para reservar en línea</span>
                </div>
              )}
              {item.requiresAdvancePayment && (
                <div className="info-item">
                  <CreditCard size={20} />
                  <span>Requiere pago por adelantado</span>
                </div>
              )}
            </div>

            <div className="item-sections">
              <section className="item-section">
                <h2>DURACIÓN</h2>
                <div className="section-content">
                  {formatDuration(item.duration)}
                </div>
              </section>

              <section className="item-section">
                <h2>COSTO</h2>
                <div className="section-content">
                  $ {item.cost} mxn
                </div>
              </section>

              {item.tags && item.tags.length > 0 && (
                <section className="item-section">
                  <h2>ETIQUETAS</h2>
                  <div className="tags-container">
                    {item.tags.map((tag, index) => (
                      <span key={index} className="catalog-tag">{tag}</span>
                    ))}
                  </div>
                </section>
              )}
            </div>
          </>
        );

      case 'subscription':
        return (
          <div className="item-sections">
            <section className="item-section">
              <h2>CANTIDAD DE EVENTOS INCLUIDOS</h2>
              <div className="section-content">
                {item.includedEvents} eventos
              </div>
            </section>

            <section className="item-section">
              <h2>COSTO</h2>
              <div className="section-content">
                $ {item.cost} mxn
              </div>
            </section>

            <section className="item-section">
              <h2>CADENCIA DE COBRO</h2>
              <div className="section-content">
                {item.cadence}
              </div>
            </section>

            {item.tags && item.tags.length > 0 && (
              <section className="item-section">
                <h2>ETIQUETAS</h2>
                <div className="tags-container">
                  {item.tags.map((tag, index) => (
                    <span key={index} className="catalog-tag">{tag}</span>
                  ))}
                </div>
              </section>
            )}
          </div>
        );

      case 'product':
        return (
          <>
            <div className="reservation-info">
              {item.requiresAdvancePayment && (
                <div className="info-item">
                  <CreditCard size={20} />
                  <span>Requiere pago por adelantado</span>
                </div>
              )}
            </div>

            <div className="item-sections">
              <section className="item-section">
                <h2>COSTO</h2>
                <div className="section-content">
                  $ {item.cost} mxn
                </div>
              </section>

              {item.tags && item.tags.length > 0 && (
                <section className="item-section">
                  <h2>ETIQUETAS</h2>
                  <div className="tags-container">
                    {item.tags.map((tag, index) => (
                      <span key={index} className="catalog-tag">{tag}</span>
                    ))}
                  </div>
                </section>
              )}
            </div>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <div className="catalog-item-details-page">
      <Container className="catalog-item-details-container">
        <div className="item-content">
          <Button variant="link" onClick={() => navigate(-1)} className="back-button">
            Volver
          </Button>
          <h1 className="item-title">{item.class_name}</h1>
          {renderItemDetails()}
        </div>

        <div className="action-buttons">
          <Button variant="outline-dark" onClick={handleDelete} className="delete-button">
            Eliminar
          </Button>
          <Button variant="dark" onClick={() => setShowEditModal(true)} className="edit-button">
            Editar
          </Button>
        </div>

        <AddClassModal
          show={showEditModal}
          handleClose={() => setShowEditModal(false)}
          saveClass={handleEditSave}
          initialData={item}
        />
      </Container>
    </div>
  );
};

export default CatalogItemDetail; 