import React, { useState, useEffect } from 'react';
import { Button, Dropdown, DropdownButton } from 'react-bootstrap';
import AddContactModal from './AddContactModal';
import AddPaymentModal from './AddPaymentModal';
import { getAuth, onAuthStateChanged} from 'firebase/auth';
import { db } from './config/firebase-config';
import { collection, query, where, getDoc, getFirestore, getDocs, addDoc, updateDoc, doc, deleteDoc, serverTimestamp } from 'firebase/firestore';
import axios from 'axios';
import './styles/MemberCard.css'; // Import the new CSS file
import './styles/SectionHeader.css';
import { ReactComponent as FilterIcon } from './icons/filter.svg';
import { useAuth } from './contexts/AuthContexts.js'; 
import emptyStateIcon from './icons/members.svg'; // Add this import
import { useNavigate } from 'react-router-dom';
import './styles/SegmentedControl.css'; // Add this import




const ContactList = () => {
    const [members, setMembers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [classes, setClasses] = useState([]);
    const [selectedMember, setSelectedMember] = useState(null);
    const [showSinglePaymentModal, setShowSinglePaymentModal] = useState(false); 
    const [filterStatus, setFilterStatus] = useState('');
    const { currentUser, additionalUserInfo } = useAuth();  // This is the correct place to call useAuth
    const [userData, setUserData] = useState(null);
    const [showAddContactModal, setShowAddContactModal] = useState(false);
    const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);
    const [selectedMemberForPayment, setSelectedMemberForPayment] = useState(null);
    const [addPaymentModalData, setAddPaymentModalData] = useState(null);
    const navigate = useNavigate();
    const [activeSegment, setActiveSegment] = useState('clients'); // Add this state

    useEffect(() => {
        const fetchUserData = async () => {
            if (!currentUser) {
                console.error('No current user found');
                return;
            }

            const db = getFirestore();
            const userRef = doc(db, 'users', currentUser.uid);
            const userSnap = await getDoc(userRef);

            if (!userSnap.exists()) {
                console.error('User data not found');
                return;
            }

            const userData = userSnap.data();
            setUserData(userData);
            console.log('User data fetched:', userData);

            fetchMembers(); // Now fetching members might depend on user data
            fetchClasses(); // Same as above
        };

        if (currentUser) {
            fetchUserData();
        }
    }, [currentUser, filterStatus]);


    const handleShowModal = (member) => {
        if (member) {
            navigate(`/clients/${member.id}`);
        } else {
            setSelectedMember(null);
            setShowModal(true);
        }
    };

    const handleCloseModal = () => setShowModal(false);

    const openAddPaymentModal = (data) => {
        console.log("Opening payment modal with data:", data);
        setAddPaymentModalData(data);
        setShowAddPaymentModal(true);
    };

    const applyFilter = (status) => {
        setFilterStatus(status);
    };

    const handleFilterChange = (event) => {
        setFilterStatus(event.target.value);
      };


      const handleFilterClick = () => {
        document.getElementById('hidden-select').click(); // Trigger the hidden select click
      };
      

      const saveMember = async (memberData) => {
        const auth = getAuth();
        const user = auth.currentUser;
        console.log('Stripe Account ID:', currentUser.stripeAccountId);
    
        if (!user) {
            console.error('No user logged in!');
            return;
        }

        const stripeAccountId = userData.stripeAccountId;

    
        try {
            if (selectedMember) {
                const docRef = doc(db, 'members', selectedMember.id);
                await updateDoc(docRef, {
                    ...memberData,
                    active_status: "active",
                    updatedBy: user.uid
                });
            } else {
                const docRef = await addDoc(collection(db, 'members'), {
                    ...memberData,
                    createdBy: user.uid,
                    createdAt: serverTimestamp(), 
                    active_status: "active",
                });
                // Ensure memberData has the newly created ID if necessary
                memberData.id = docRef.id;
                memberData.createdAt = serverTimestamp();
                console.log("New member added:", memberData);
    
                // Create Stripe customer
                try {
                    console.log('Stripe Account ID:', stripeAccountId);

                    if (!stripeAccountId) {
                        console.error('Stripe account ID is missing, cannot create Stripe customer');
                        return;
                    }
                    const response = await axios.post('/api/members/create-stripe-customer', {
                        id: memberData.id,
                        contact_name: memberData.contact_name,
                        number: memberData.number,
                        stripeAccountId: stripeAccountId
                    });
                    console.log('Stripe Customer Created:', response.data);
                    
                    // Add the stripeCustomerId to memberData
                    const stripeCustomerId = response.data.customer.id;
                    memberData.stripeCustomerId = stripeCustomerId;

                    // Update the member document with the stripeCustomerId
                    try {
                        await updateDoc(docRef, {
                            stripeCustomerId: stripeCustomerId
                        });
                        console.log('Firestore document updated with Stripe customer ID');
                    } catch (updateError) {
                        console.error('Failed to update Firestore document with Stripe customer ID:', updateError);
                        // The member is created in Stripe but not updated in Firestore
                        // You might want to handle this case (e.g., retry, manual update, etc.)
                    }
                } catch (error) {
                    console.error('Failed to create Stripe customer:', error);
                }
            }
    
            // Refresh member list after all operations
            fetchMembers();
            return memberData; // Return the saved member data, now including stripeCustomerId
        } catch (error) {
            console.error('Error saving member:', error);
            throw error; // Rethrow the error to handle it in the calling function
        }
    };

    const deleteMember = async (memberId) => {
        try {
            const memberRef = doc(db, 'members', memberId);
            await deleteDoc(memberRef);
            fetchMembers(); // Refresh the members list after deletion
        } catch (error) {
            console.error('Error deleting member:', error);
        }
    };

    const updateMemberActiveStatus = async (memberId) => {
        const auth = getAuth();
        const user = auth.currentUser;
    
        if (!user) {
            console.error('No user logged in!');
            return;
        }
    
        try {
            const docRef = doc(db, 'members', memberId);
            await updateDoc(docRef, {
                active_status: "inactive"  // Set status to inactive instead of deleting
            });
            fetchMembers();  // Refresh the list of members after updating
        } catch (error) {
            console.error('Error updating member status:', error);
        }
    };

    const fetchMembers = async () => {
        const auth = getAuth();
        const user = auth.currentUser;
        if (!user) {
          console.error('No user logged in!');
          return;
        }
      
        let baseQuery = collection(db, 'members');
        let conditions = [where("createdBy", "==", user.uid)];
      
        if (filterStatus && filterStatus !== 'all') { // Apply filter only if not 'all'
          conditions.push(where("status", "==", filterStatus));
        }
      
        const q = query(baseQuery, ...conditions);
        try {
          const querySnapshot = await getDocs(q);
          const membersData = querySnapshot.docs
            .map(doc => ({ id: doc.id, ...doc.data() }))
            .sort((a, b) => a.contact_name.localeCompare(b.contact_name)); // Sorting alphabetically by contact_name

          setMembers(membersData);
        } catch ( error ) {
          console.error('Error fetching members:', error);
        }
      };
      

    const fetchClasses = async () => {
        const auth = getAuth();
        const user = auth.currentUser;
        if (!user) return;

        const q = query(collection(db, 'classes'), where("createdBy", "==", user.uid));
        try {
            const querySnapshot = await getDocs(q);
            const fetchedClasses = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setClasses(fetchedClasses);
        } catch (error) {
            console.error('Error fetching classes:', error);
        }
    };

    const getClassNames = (classIds) => {
        if (!classIds) return '';
        return classIds.map(id => {
            const cls = classes.find(c => c.id === id);
            return cls ? cls.class_name : '';
        }).join(', ');
    };

    const getSubscriptionPills = (activeSuscriptions) => {
        if (!activeSuscriptions || activeSuscriptions.length === 0) {
            return <span className="no-subscriptions">No hay sucripciones activas</span>;
        }
        
        return activeSuscriptions.map((sub, index) => (
            <span key={index} className="subscription-pill">
                {sub.className}
            </span>
        ));
    };

    const sendPaymentLink = async (memberId, method) => {
        if (!currentUser || !additionalUserInfo.stripeAccountId) {
            alert("Stripe account ID is not available. Please log in again.");
            return;
        }

        const member = members.find(m => m.id === memberId);
        if (!member) {
            alert("Member data not found.");
            return;
        }

        const dataToSend = {
            memberId,
            stripeAccountId: additionalUserInfo.stripeAccountId,
            classIds: member.classIds,
            phoneNumber: member.number,
            memberName: member.contact_name,
            userId: member.createdBy,
            customerId: member.stripeCustomerId,
            method
        };

        console.log("Sending payment link with the following data:", dataToSend);

        try {
            // Send payment link
            const response = await axios.post('/api/payments/send-payment-link', dataToSend);
            
            // Calculate total amount and get class details
            let totalAmount = 0;
            const itemsPaid = member.classIds.map(classId => {
                const classData = classes.find(c => c.id === classId);
                if (classData) {
                    totalAmount += classData.cost;
                    return {
                        id: classData.id,
                        name: classData.class_name,
                        cost: classData.cost
                    };
                }
                return null;
            }).filter(Boolean); // Remove any null entries

            // Create new payment record in Firebase
            const paymentData = {
                clientName: member.contact_name,
                date: serverTimestamp(),
                itemsPaid: itemsPaid,
                totalAmount: totalAmount,
                paymentMethod: 'Enlace de Pago',
                status: 'Pending',
                memberId: member.id,
                createdBy: currentUser.uid
            };

            await addDoc(collection(db, 'payments'), paymentData);

            console.log('Payment record created:', paymentData);
            alert('Payment link sent successfully and payment record created!');
        } catch (error) {
            console.error('Error sending payment link or creating payment record:', error);
            alert(`Error: ${error.message}`);
        }
    };


    const sendSinglePayment = async (memberId, method) => {
        
        if (!currentUser || !additionalUserInfo.stripeAccountId) {
            alert("Stripe account ID is not available. Please log in again.");
            return;
        }

        const member = members.find(m => m.id === memberId); // Get the member data
        if (!member) {
            alert("Member data not found.");
            return;
        }

        const dataToSend = {
            memberId,
            stripeAccountId: additionalUserInfo.stripeAccountId,
            classIds: member.classIds,
            phoneNumber: member.number,
            memberName : member.contact_name,
            userId: member.createdBy,
            customerId: member.stripeCustomerId,
            method
        };

        console.log("Sending payment link with the following data:", dataToSend);

        try {
            const response = await axios.post('/api/payments/send-payment-link', dataToSend);
            alert('Payment link sent successfully!');
        } catch (error) {
            console.error('Error sending payment link:', error);
            alert(`Failed to send payment link: ${error.message}`);
        }
    };


    const handleShowSinglePaymentModal = (member, userData) => {
        setSelectedMember(member);
        setUserData(userData);
        setShowSinglePaymentModal(true);
    };

    const handleCloseSinglePaymentModal = () => setShowSinglePaymentModal(false);


    const getStatusClass = (status) => {
        switch (status) {
            case 'Corriente':
                return 'corriente';
            case 'Pendiente':
                return 'pendiente';
            case 'Atrasado':
                return 'atrasado';
            case 'Nuevo':
                return 'nuevo';
            default:
                return '';
        }
    };

    // Update Dropdown.Item onClick to call applyFilter
<Dropdown.Item onClick={() => applyFilter('Corriente')}>Corriente</Dropdown.Item>

    // Add this function to filter members based on segment
    const getFilteredMembers = () => {
        let filteredMembers = [...members];
        
        // Apply status filter if selected
        if (filterStatus && filterStatus !== 'all') {
            filteredMembers = filteredMembers.filter(member => member.status === filterStatus);
        }

        // Apply segment filter
        if (activeSegment === 'clients') {
            return filteredMembers.filter(member => 
                !member.activeSuscriptions || member.activeSuscriptions.length === 0
            );
        } else {
            return filteredMembers.filter(member => 
                member.activeSuscriptions && member.activeSuscriptions.length > 0
            );
        }
    };

    return (
        <div>
            <div className="segmented-control">
                {[
                    { id: 'clients', label: 'Clientes' },
                    { id: 'subscribed', label: 'Clientes Suscritos' }
                ].map(segment => (
                    <button
                        key={segment.id}
                        className={`segment-button ${activeSegment === segment.id ? 'active' : ''}`}
                        onClick={() => setActiveSegment(segment.id)}
                    >
                        {segment.label}
                    </button>
                ))}
            </div>

            <div className="section-header">
                <h3 className='section-title'>
                    {getFilteredMembers().length} {activeSegment === 'clients' ? 'clientes' : 'clientes suscritos'}
                </h3>
                <div className="button-group">
                    <div className="filter-container">
                        <select
                            id="filter-select"
                            value={filterStatus}
                            onChange={handleFilterChange}
                            className={`button-filter ${filterStatus && filterStatus !== 'all' ? 'active-filter' : ''}`}
                        >
                            <option value="all">Filtrar</option>
                            <option value="Corriente">Corriente</option>
                            <option value="Pendiente">Pendiente</option>
                            <option value="Atrasado">Atrasado</option>
                            <option value="Nuevo">Nuevo</option>
                        </select>
                    </div>
                </div>
            </div>

            {getFilteredMembers().length > 0 ? (
                <div className="row">
                    {getFilteredMembers().map((member, index) => (
                        <div key={index} className="col-sm-4">
                            <div className="card">
                                <div className={`card-status ${getStatusClass(member.status)}`}>{member.status}</div>
                                <div className="card-body">
                                    <h5 className="card-title">{member.contact_name}</h5>
                                    <p className="card-contact-info">
                                        <a href={`https://wa.me/${member.number}`}>{member.number}</a>
                                    </p>
                                    <div className="card-subscriptions">
                                        {getSubscriptionPills(member.activeSuscriptions)}
                                    </div>
                                    
                                    <div className="card-actions">
                                        <div className="card-edit" onClick={() => handleShowModal(member)}>Ver más</div>
                                        {/*<div className="card-send-sms" onClick={() => sendPaymentLink(member.id, 'sms')}>Enviar SMS</div>*/}
                                        {/*<div className="card-edit" onClick={() => handleShowSinglePaymentModal(member, userData)}>Agregar Pago</div>*/}
                                        {/*<Button variant="secondary" onClick={() => sendSPEIPaymentLink(member.id)}>Enviar SPEI</Button>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="empty-state-container">
                    {filterStatus !== 'all' ? (
                        <>
                            <p>No tienes clientes con esa característica.</p>
                            <button className="add-contact-button" onClick={() => handleShowModal(null)}>
                                + Agregar
                            </button>
                        </>
                    ) : (
                        <>
                            <img src={emptyStateIcon} alt="No contacts" className="empty-state-icon" />
                            <h2>
                                {activeSegment === 'clients' 
                                    ? 'Aún no tienes clientes' 
                                    : 'Aún no tienes clientes suscritos'}
                            </h2>
                            <p>
                                {activeSegment === 'clients'
                                    ? '¡Comienza a crecer! Agrega contactos y mantén tu lista actualizada.'
                                    : '¡Comienza a crecer! Agrega suscripciones a tus clientes existentes.'}
                            </p>
                            <button className="add-contact-button" onClick={() => handleShowModal(null)}>
                                + Agregar
                            </button>
                        </>
                    )}
                </div>
            )}

            <div className="fab-container">
                <button className="fab" onClick={() => handleShowModal(null)}>+</button>
            </div>

            <AddContactModal
                show={showModal}
                handleClose={handleCloseModal}
                saveMember={saveMember}
                deleteMember={deleteMember}
                classes={classes}
                member={selectedMember}
                openAddPaymentModal={openAddPaymentModal}
            />  
        
            <AddPaymentModal
                show={showAddPaymentModal}
                handleClose={() => setShowAddPaymentModal(false)}
                memberData={addPaymentModalData?.memberData}
                memberId={addPaymentModalData?.memberId}
            />
        </div>
    );
};

export default ContactList;