import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './styles/OnboardingFlow.css';  // This line imports the CSS styles

const OnboardingFlow = () => {
  const [communityName, setCommunityName] = useState('');
  const [communityType, setCommunityType] = useState('');
  const [businessDescriptor, setBusinessDescriptor] = useState(''); // Add this line
  const [availability, setAvailability] = useState({
    Lu: true, Ma: true, Mi: true, Ju: true, Vi: true, Sa: false, Do: false
  });
  const [openingTime, setOpeningTime] = useState('08:00');
  const [closingTime, setClosingTime] = useState('22:00');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCommunityData = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        console.error('No user logged in!');
        return;
      }

      const idToken = await user.getIdToken();
      const communityId = user.uid; // Assuming the user's UID is the community ID

      try {
        const response = await axios.get(`/api/community/details/${communityId}`, {
          headers: { Authorization: `Bearer ${idToken}` }
        });

        if (response.data) {
          const { name, communityType, businessDescriptor, availability } = response.data;
          setCommunityName(name || '');
          setCommunityType(communityType || '');
          setBusinessDescriptor(businessDescriptor || '');
          if (availability) {
            if (availability.days) {
              setAvailability(availability.days);
            }
            if (availability.hours) {
              setOpeningTime(availability.hours.open || '08:00');
              setClosingTime(availability.hours.close || '22:00');
            }
          }
        }
      } catch (error) {
        console.error('Error fetching community data:', error);
      }
    };

    fetchCommunityData();
  }, []);

  const handleAvailabilityChange = (day) => {
    setAvailability(prev => ({ ...prev, [day]: !prev[day] }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const auth = getAuth();
    const user = auth.currentUser;
  
    if (!user) {
      console.error('No user logged in!');
      return;
    }
  
    user.getIdToken().then(async (idToken) => {
      const communityId = user.uid;
  
      try {
        const response = await axios.post('/api/community/update', {
          communityId,
          communityName,
          communityType,
          businessDescriptor,
          availability: {
            days: availability,
            hours: {
              open: openingTime,
              close: closingTime
            }
          }
        }, {
          headers: {
            Authorization: `Bearer ${idToken}`
          }
        });
  
        if (response.status === 200) {
          console.log('Community updated successfully');
          navigate('/profile');
        } else {
          console.error('Failed to update community data');
        }
      } catch (error) {
        console.error('Error submitting community updates:', error);
      }
    }).catch(error => {
      console.error('Failed to get Firebase ID token', error);
    });
  };

  return (
    <div className="onboarding-container">
      <div className="header">
        <button onClick={() => navigate(-1)} className="back-button">Volver</button>
        <h1 className="onboarding-title">Información del negocio</h1>
      </div>
      <form onSubmit={handleSubmit} className="onboarding-form">
        <div className="input-group">
          <label htmlFor="communityName">Nombre de tu negocio</label>
          <input
            type="text"
            id="communityName"
            value={communityName}
            onChange={e => setCommunityName(e.target.value)}
            required
          />
        </div>

        <div className="input-group">
          <label htmlFor="communityType">Categoría</label>
          <select
            id="communityType"
            value={communityType}
            onChange={e => setCommunityType(e.target.value)}
            className="type-select"
            required
          >
            <option value="">Selecciona una opción...</option>
            <option value="Wellness">Wellness</option>
            <option value="Fitness">Fitness</option>
            <option value="Servicios académicos">Servicios académicos</option>
            <option value="Artes">Artes</option>
            <option value="Salud">Salud</option>
            <option value="Mantenimiento">Mantenimiento</option>
            <option value="Servicios profesionales">Servicios profesionales</option>
            <option value="Startup">Startup</option>
            <option value="Otros">Otros</option>
          </select>
        </div>

        <div className="input-group">
          <label htmlFor="businessDescriptor">Giro del negocio (opcional)</label>
          <input
            type="text"
            id="businessDescriptor"
            value={businessDescriptor}
            onChange={e => setBusinessDescriptor(e.target.value)}
            placeholder="Ej. Estudio de yoga, diseñador freelance"
          />
        </div>

        <div className="availability-section">
          <h2>DISPONIBILIDAD Y HORARIOS</h2>
          <div className="availability-days">
            <p>Disponibilidad de días</p>
            <div className="day-buttons">
              {['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'].map(day => (
                <button
                  key={day}
                  type="button"
                  className={`day-button ${availability[day] ? 'active' : ''}`}
                  onClick={() => handleAvailabilityChange(day)}
                >
                  {day}
                </button>
              ))}
            </div>
          </div>
          <div className="availability-hours">
            <div>
              <label htmlFor="openingTime">Horario de apertura</label>
              <input
                type="time"
                id="openingTime"
                value={openingTime}
                onChange={(e) => setOpeningTime(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="closingTime">Horario de cierre</label>
              <input
                type="time"
                id="closingTime"
                value={closingTime}
                onChange={(e) => setClosingTime(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="save-button-container">
          <button type="submit" className="save-button btn btn-primary">Guardar información</button>
        </div>
      </form>
    </div>
  );
};

export default OnboardingFlow;