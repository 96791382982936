import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, Timestamp, orderBy, limit, getDoc, doc } from 'firebase/firestore';
import { db, auth } from './config/firebase-config.js';
import './styles/SectionHeader.css'
import './styles/Reports.css';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'axios';

function ClientesTotales() {
  const [totalClients, setTotalClients] = useState(0);
  const [newClientsThisMonth, setNewClientsThisMonth] = useState(0);

  useEffect(() => {
    async function fetchData() {
      try {
        const user = auth.currentUser;
        if (!user) {
          console.error("No authenticated user found");
          return;
        }

        const membersRef = collection(db, 'members');
        const activeQuery = query(
          membersRef, 
          where('createdBy', '==', user.uid),
        );
        const querySnapshot = await getDocs(activeQuery);
        
        const total = querySnapshot.size;
        
        const now = new Date();
        const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        const newThisMonth = querySnapshot.docs.filter(doc => {
          const createdAt = doc.data().createdAt?.toDate();
          return createdAt && createdAt >= firstDayOfMonth;
        }).length;

        setTotalClients(total);
        setNewClientsThisMonth(newThisMonth);
      } catch (error) {
        console.error("Error fetching client data:", error);
      }
    }
    fetchData();
  }, []);

  return (
    <div className="report-card">
      <h2>Clientes Totales</h2>
      <div className="report-content">
        <div className="total">{totalClients.toLocaleString()}</div>
        <div className="change">+{newClientsThisMonth} este mes</div>
      </div>
    </div>
  );
}

function IngresosMensuales() {
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [percentageChange, setPercentageChange] = useState(0);

  useEffect(() => {
    async function fetchData() {
      try {
        const user = auth.currentUser;
        if (!user) {
          console.error("No authenticated user found");
          return;
        }
        console.log("Current user ID:", user.uid);

        const paymentsRef = collection(db, 'payments');
        const paymentsQuery = query(
          paymentsRef,
          where('status', '==', 'Pagado')
        );

        const querySnapshot = await getDocs(paymentsQuery);
        console.log("Total documents fetched:", querySnapshot.size);

        let total = 0;
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          console.log("Processing payment:", data);
          
          if (data.createdBy === user.uid || data.communityId === user.uid) {
            console.log("Payment matches user ID");
            if (data.totalAmount) {
              console.log("Adding to total:", data.totalAmount);
              total += data.totalAmount;
            } else {
              console.log("Payment has no totalAmount");
            }
          } else {
            console.log("Payment does not match user ID");
          }
        });

        console.log("Final total earnings:", total);
        setTotalEarnings(total);

        // Calculate percentage change (simplified)
        setPercentageChange(15); // Placeholder value

      } catch (error) {
        console.error("Error fetching earnings data:", error);
      }
    }
    fetchData();
  }, []);

  return (
    <div className="report-card">
      <h2>Ingresos de {new Date().toLocaleString('es', { month: 'long' })}</h2>
      <div className="report-content">
        <div className="total">${totalEarnings.toLocaleString()}</div>
        <div className="change">+{percentageChange}% vs mes anterior</div>
      </div>
    </div>
  );
}

function SuscripcionesActivas() {
  const [activeSubs, setActiveSubs] = useState(0);
  const [percentageChange, setPercentageChange] = useState(0);

  useEffect(() => {
    async function fetchData() {
      try {
        const user = auth.currentUser;
        if (!user) {
          console.error("No authenticated user found");
          return;
        }

        const membersRef = collection(db, 'members');
        const activeSubsQuery = query(
          membersRef, 
          where('createdBy', '==', user.uid),
          where('clientSubscribed', '==', true)
        );
        const querySnapshot = await getDocs(activeSubsQuery);
        
        const total = querySnapshot.size;
        setActiveSubs(total);

        // Calculate percentage change (simplified)
        // You might want to implement a more sophisticated comparison with the previous month
        setPercentageChange(5); // Placeholder value

      } catch (error) {
        console.error("Error fetching active subscriptions data:", error);
      }
    }
    fetchData();
  }, []);

  return (
    <div className="report-card">
      <h2>Suscripciones Activas</h2>
      <div className="report-content">
        <div className="total">{activeSubs.toLocaleString()}</div>
        <div className="change">+{percentageChange}% vs mes anterior</div>
      </div>
    </div>
  );
}

function TopProductosVendidos() {
  const [topProducts, setTopProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const user = auth.currentUser;
        if (!user) {
          console.error("No authenticated user found");
          setError("No authenticated user found");
          return;
        }

        console.log("Fetching payments for user:", user.uid);

        const paymentsRef = collection(db, 'payments');
        const paymentsQuery = query(
          paymentsRef,
          where('createdBy', '==', user.uid),
          limit(1000)
        );

        const querySnapshot = await getDocs(paymentsQuery);
        console.log("Number of documents fetched:", querySnapshot.size);
        
        const productCounts = {};
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          console.log("Payment document:", data);  // Log each payment document
          
          if (data.status === 'Pagado') {
            console.log("Found paid payment:", data);
            if (data.itemsPaid && data.itemsPaid.length > 0) {
              console.log("Items paid:", data.itemsPaid);
              data.itemsPaid.forEach(item => {
                if (item.name) {
                  productCounts[item.name] = (productCounts[item.name] || 0) + 1;
                  console.log(`Counted product: ${item.name}`);
                }
              });
            } else {
              console.log("No items paid found in this document");
            }
          } else {
            console.log("Payment not marked as Pagado");
          }
        });

        console.log("Product counts:", productCounts);

        const sortedProducts = Object.entries(productCounts)
          .sort(([,a], [,b]) => b - a)
          .slice(0, 3)
          .map(([name, count]) => ({ name, count }));

        console.log("Sorted top products:", sortedProducts);

        setTopProducts(sortedProducts);
      } catch (error) {
        console.error("Error fetching top products data:", error);
        setError("Failed to fetch top products");
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  if (loading) return <div className="report-card">Loading top products...</div>;
  if (error) return <div className="report-card">Error: {error}</div>;

  return (
    <div className="report-card">
      <h2>Top 3 Productos Vendidos</h2>
      <div className="report-content">
        {topProducts.length > 0 ? (
          <ol>
            {topProducts.map((product, index) => (
              <li key={index}>
                {product.name}: {product.count} ventas
              </li>
            ))}
          </ol>
        ) : (
          <p>No hay datos de productos vendidos disponibles.</p>
        )}
      </div>
    </div>
  );
}

function DistribucionMetodosPago() {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const user = auth.currentUser;
        if (!user) {
          console.error("No authenticated user found");
          setError("No authenticated user found");
          return;
        }

        console.log("Fetching payments for user:", user.uid);

        const paymentsRef = collection(db, 'payments');
        const paymentsQuery = query(
          paymentsRef,
          where('createdBy', '==', user.uid),
          limit(1000)
        );

        const querySnapshot = await getDocs(paymentsQuery);
        console.log("Number of documents fetched:", querySnapshot.size);
        
        const methodCounts = {};
        let totalPayments = 0;

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.status === 'Pagado' && data.paymentMethod) {
            methodCounts[data.paymentMethod] = (methodCounts[data.paymentMethod] || 0) + 1;
            totalPayments++;
          }
        });

        console.log("Payment method counts:", methodCounts);
        console.log("Total payments:", totalPayments);

        const methodPercentages = Object.entries(methodCounts).map(([method, count]) => ({
          method,
          percentage: (count / totalPayments) * 100
        }));

        methodPercentages.sort((a, b) => b.percentage - a.percentage);

        console.log("Payment method percentages:", methodPercentages);

        setPaymentMethods(methodPercentages);
      } catch (error) {
        console.error("Error fetching payment method data:", error);
        setError("Failed to fetch payment method distribution");
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  if (loading) return <div className="report-card">Loading payment method distribution...</div>;
  if (error) return <div className="report-card">Error: {error}</div>;

  return (
    <div className="report-card">
      <h2>Métodos de pago más frecuentes</h2>
      <div className="report-content">
        {paymentMethods.length > 0 ? (
          <div>
            {paymentMethods.map((item, index) => (
              <div key={index} style={{ marginBottom: '5px' }}>
                {item.method}: <strong>{item.percentage.toFixed(2)}%</strong>
              </div>
            ))}
          </div>
        ) : (
          <p>No hay datos de métodos de pago disponibles.</p>
        )}
      </div>
    </div>
  );
}

function BalanceCards() {
  const [balance, setBalance] = useState({ available: 0, pending: 0 });

  useEffect(() => {
    const fetchBalance = async (userId) => {
      if (!userId) return;
      try {
        const response = await axios.get(`/api/get-balance/${userId}`);
        const balanceData = response.data;
        let available = 0;
        let pending = 0;
        
        balanceData.available.forEach(item => {
          available += item.amount;
        });
    
        balanceData.pending.forEach(item => {
          pending += item.amount;
        });
    
        setBalance({ 
          available: available / 100,
          pending: pending / 100
        });
      } catch (error) {
        console.error('Error fetching balance:', error);
      }
    };

    const user = auth.currentUser;
    if (user) {
      fetchBalance(user.uid);
    }
  }, []);

  // Helper function to format currency
  const formatCurrency = (amount) => {
    return amount.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  };

  return (
    <div className="report-card balance-card-container">
      <h2>Flujo de Dinero (Liga de Pago)</h2>
      <div className="balance-cards">
        <div className="balance-card-item">
          <h3>Dinero en Tránsito</h3>
          <p className="balance-amount">{formatCurrency(balance.pending)}</p>
          <p className="balance-description">Cobrado y en procesamiento.</p>
          <p className="balance-info">Este monto ya fue cobrado a los clientes y está siendo procesado para estar disponible para retiro.</p>
        </div>
        <div className="balance-card-item">
          <h3>Dinero Listo para Retirar</h3>
          <p className="balance-amount">{formatCurrency(balance.available)}</p>
          <p className="balance-description">Disponible en Stripe</p>
          <p className="balance-info">Este monto será transferido automáticamente a tu cuenta bancaria en los próximos 7 días.</p>
        </div>
      </div>
    </div>
  );
}

function Reports() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <div>Please log in to view reports.</div>;
  }

  return (
    <div className="reports-container">
     <div className="section-header">
        <h3 className='section-title'>Reportes</h3>
      </div>
      
      <div className="reports-grid">
        
        <ClientesTotales />
        <SuscripcionesActivas />
        <BalanceCards />
        <IngresosMensuales />
        <TopProductosVendidos />
        <DistribucionMetodosPago />
        {/* Add more report components here */}
      </div>
    </div>
  );
}

export default Reports;