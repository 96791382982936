import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import './styles/EditEventModal.css'; // Change this import

const EditEventModal = ({ show, handleClose, handleDelete, event }) => {
  const [deleteOption, setDeleteOption] = useState('this');
  const [showDeleteOptions, setShowDeleteOptions] = useState(false);

  const formatDate = (dateString) => {
    const options = { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric', 
      hour: '2-digit', 
      minute: '2-digit'
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleDeleteClick = () => {
    if (event.extendedProps?.isRecurring) {
      setShowDeleteOptions(true);
    } else {
      confirmDelete();
    }
  };

  const confirmDelete = () => {
    if (window.confirm('Are you sure you want to delete this event?')) {
      handleDelete(event.id, deleteOption);
      handleClose();
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Event Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {event && (
          <Form className="edit-event-form">
            <Form.Group>
              <Form.Label>Título del Evento</Form.Label>
              <Form.Control plaintext readOnly defaultValue={event.title} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Hora de Inicio</Form.Label>
              <Form.Control plaintext readOnly defaultValue={formatDate(event.start)} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Hora de Fin</Form.Label>
              <Form.Control plaintext readOnly defaultValue={formatDate(event.end)} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Servicio</Form.Label>
              <Form.Control plaintext readOnly defaultValue={event.extendedProps.class_name} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Cliente</Form.Label>
              <Form.Control plaintext readOnly defaultValue={event.extendedProps.memberName} />
            </Form.Group>
            {event.rrule && (
              <Form.Group>
                <Form.Label>Recurrencia</Form.Label>
                <Form.Control plaintext readOnly defaultValue={event.rrule} />
              </Form.Group>
            )}
            {showDeleteOptions && event.extendedProps?.isRecurring && (
              <Form.Group>
                <Form.Label>Opciones de Eliminación</Form.Label>
                <Form.Control
                  as="select"
                  value={deleteOption}
                  onChange={(e) => setDeleteOption(e.target.value)}
                >
                  <option value="this">Solo este evento</option>
                  <option value="future">Este evento y todos los futuros</option>
                  <option value="all">Todos los eventos</option>
                </Form.Control>
              </Form.Group>
            )}
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        {!showDeleteOptions && (
          <Button variant="link" className="delete-button" onClick={handleDeleteClick}>
            Eliminar Evento
          </Button>
        )}
        {showDeleteOptions && (
          <Button variant="danger" onClick={confirmDelete}>
            Confirmar Eliminación
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default EditEventModal;
