import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './styles/PaymentSettings.css';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles/CustomToast.css';

function PaymentSettings() {
    const navigate = useNavigate();
    const stripeConfigRef = useRef(null);
    const [paymentMethods, setPaymentMethods] = useState({
        efectivo: true,
        tarjeta: true,
        transferencia: false,
        ligaPago: false
    });
    const [bankInfo, setBankInfo] = useState({
        banco: '',
        clabe: '',
        numeroCuenta: '',
        nombreCompleto: ''
    });
    const [state, setState] = useState({
        user: null,
        communityId: null,
        isLoading: true,
        error: null
    });
    const [paymentLogic, setPaymentLogic] = useState({
        paymentDay: 1,
        availableDays: 7,
        penaltyRate: 5
    });
    const [hasChanges, setHasChanges] = useState(false);
    const [activePlan, setActivePlan] = useState(null);

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            console.log('Current user:', currentUser);
            
            if (currentUser) {
                try {
                    // Try to get communityId from custom claims
                    const idTokenResult = await currentUser.getIdTokenResult();
                    console.log('ID Token Result:', idTokenResult);
                    if (idTokenResult.claims.communityId) {
                        setState(prevState => ({
                            ...prevState,
                            user: currentUser,
                            communityId: idTokenResult.claims.communityId,
                            isLoading: false
                        }));
                    } else {
                        // If not in claims, try to fetch from a users collection
                        const db = getFirestore();
                        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
                        if (userDoc.exists() && userDoc.data().communityId) {
                            setState(prevState => ({
                                ...prevState,
                                user: currentUser,
                                communityId: userDoc.data().communityId,
                                isLoading: false
                            }));
                        } else {
                            throw new Error('Community ID not found in user document');
                        }
                    }
                } catch (error) {
                    console.error('Error fetching community ID:', error);
                    setState(prevState => ({
                        ...prevState,
                        error: error.message,
                        isLoading: false
                    }));
                }
            } else {
                setState(prevState => ({
                    ...prevState,
                    user: null,
                    communityId: null,
                    isLoading: false
                }));
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        console.log('Current state:', state);
    }, [state]);

    useEffect(() => {
        if (state.communityId) {
            fetchPaymentSettings();
            fetchActivePlan();
        }
    }, [state.communityId]);

    const fetchPaymentSettings = async () => {
        try {
            const db = getFirestore();
            const communityRef = doc(db, 'communities', state.communityId);
            const communityDoc = await getDoc(communityRef);

            if (communityDoc.exists()) {
                const data = communityDoc.data();
                setPaymentLogic({
                    paymentDay: data.startDay || 1,
                    availableDays: (data.endDay - data.startDay + 1) || 7,
                    penaltyRate: data.penaltyRate || 5
                });
                // Load bank transfer configuration
                setBankInfo({
                    banco: data.bankName || '',
                    clabe: data.clabeNumber || '',
                    numeroCuenta: data.bankAccountNumber || '',
                    nombreCompleto: data.fullName || ''
                });
                // Load payment method toggles
                if (data.paymentMethods) {
                    setPaymentMethods(data.paymentMethods);
                }
            } else {
                console.log('No payment settings found, using defaults');
            }
        } catch (error) {
            console.error('Error fetching payment settings:', error);
            setState(prevState => ({
                ...prevState,
                error: 'Failed to load payment settings'
            }));
        }
    };

    const fetchActivePlan = async () => {
        try {
            const db = getFirestore();
            const communityRef = doc(db, 'communities', state.communityId);
            const communityDoc = await getDoc(communityRef);

            if (communityDoc.exists()) {
                const data = communityDoc.data();
                setActivePlan(data.activePlan || null);
            } else {
                console.log('No community document found');
            }
        } catch (error) {
            console.error('Error fetching active plan:', error);
        }
    };

    const handleToggle = (method) => {
        setPaymentMethods(prev => {
            const newMethods = { ...prev, [method]: !prev[method] };
            setHasChanges(true);
            return newMethods;
        });
    };

    const handleBankInfoChange = (e) => {
        setBankInfo(prev => {
            const newInfo = { ...prev, [e.target.name]: e.target.value };
            setHasChanges(true);
            return newInfo;
        });
    };
 
    const handlePaymentLogicChange = (field, value) => {
        setPaymentLogic(prev => {
            const newLogic = { ...prev, [field]: value };
            setHasChanges(true);
            return newLogic;
        });
    };

    const handleStripeSetup = async () => {
        try {
            if (!state.user) {
                throw new Error('User not authenticated');
            }

            const db = getFirestore();
            const userRef = doc(db, 'users', state.user.uid);
            const userDoc = await getDoc(userRef);

            if (userDoc.exists()) {
                const userData = userDoc.data();
                if (userData.stripeAccountId) {
                    await fetchStripeData(userData.stripeAccountId);
                } else {
                    console.error('No Stripe account ID found for this user');
                    alert('No se encontró una cuenta de Stripe asociada. Por favor, contacte al soporte.');
                }
            } else {
                console.error('User document not found');
                alert('No se pudo encontrar la información del usuario. Por favor, intente iniciar sesión nuevamente.');
            }
        } catch (error) {
            console.error('Error setting up Stripe:', error);
            alert('Ocurrió un error al configurar Stripe. Por favor, intente de nuevo más tarde.');
        }
    };

    const handleSave = async () => {
        if (state.isLoading) {
            alert('Please wait while we load your information.');
            return;
        }

        if (state.error) {
            alert(`Error: ${state.error}. Please try logging in again or contact support.`);
            return;
        }

        if (!state.user || !state.communityId) {
            alert('Error: User or community information is missing. Please try logging in again.');
            return;
        }

        try {
            const db = getFirestore();

            console.log('Saving with communityId:', state.communityId);

            // Update the community document in Firestore
            const communityRef = doc(db, 'communities', state.communityId);
            await updateDoc(communityRef, {
                startDay: paymentLogic.paymentDay,
                endDay: paymentLogic.paymentDay + paymentLogic.availableDays - 1,
                penaltyRate: paymentLogic.penaltyRate,
                // Add bank transfer configuration
                bankName: bankInfo.banco,
                clabeNumber: bankInfo.clabe,
                bankAccountNumber: bankInfo.numeroCuenta,
                fullName: bankInfo.nombreCompleto,
                // Add payment method toggles
                paymentMethods: paymentMethods
            });

            setHasChanges(false);
            // Show success toast
            toast.success(
                "Cambios guardados exitosamente",
                {
                    className: 'custom-toast',
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                }
            );
        } catch (error) {
            console.error('Error saving settings:', error);
            // Show error toast
            toast.error('Error al guardar los cambios: ' + error.message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

    useEffect(() => {
        console.log('hasChanges:', hasChanges);
    }, [hasChanges]);

    if (state.isLoading) {
        return <div>Loading...</div>;
    }

    if (state.error) {
        return <div>Error: {state.error}</div>;
    }

    const fetchStripeData = async (accountId) => {
        try {
            if (!accountId) {
                throw new Error('No accountId provided');
            }
            console.log('Fetching Stripe data for account:', accountId);
            const response = await axios.post('/api/stripe/edit-connect-account', { accountId });
            const { url } = response.data;
            if (url) {
                window.location.href = url;  // Redirect immediately when URL is fetched
            }
        } catch (error) {
            console.error('Error fetching Stripe account data:', error.message);
        }
     };

    const isCobranzaPlan = activePlan === 'cobranza';

    const handleUpgradeClick = () => {
        navigate('/select-plan');
    };

    return (
        <div className="payment-settings">
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="header">
                <button onClick={() => navigate(-1)} className="back-button">Volver</button>
                <h1>Pagos</h1>
            </div>

            <section className="payment-reminders">
                <h2>RECORDATORIOS DE PAGO</h2>
                {!isCobranzaPlan && (
                    <div className="plan-message">
                        <p>Los recordatorios de pago están disponibles solo para usuarios de Plan Cobranza.</p>
                        <button onClick={handleUpgradeClick} className="upgrade-button">
                            Actualizar Plan
                        </button>
                    </div>
                )}
                <p>Automatiza tus cobros con recordatorios de pago. Selecciona los métodos a incluir en tus recordatorios.</p>
                
                {Object.entries(paymentMethods).map(([method, isEnabled]) => (
                    <div key={method} className="payment-method-toggle">
                        <span>{method.charAt(0).toUpperCase() + method.slice(1)}</span>
                        <label className="switch">
                            <input
                                type="checkbox"
                                checked={isEnabled}
                                onChange={() => handleToggle(method)}
                                disabled={!isCobranzaPlan}
                            />
                            <span className="slider round"></span>
                        </label>
                    </div>
                ))}
            </section>

            {paymentMethods.transferencia && (
                <section className="bank-transfer-config">
                    <h2>CONFIGURACIÓN DE TRANSFERENCIA BANCARIA (CLABE)</h2>
                    <p>La información que agregues aquí se enviará como parte de los mensajes de recordatorio de pago para tus clientes. Asegúrate que la información sea correcta para evitar errores en los pagos.</p>
                    <div className="input-group">
                        <label htmlFor="banco">Banco</label>
                        <input
                            id="banco"
                            type="text"
                            name="banco"
                            value={bankInfo.banco}
                            onChange={handleBankInfoChange}
                            placeholder="Ej. BBVA, Santander, Banorte"
                            disabled={!isCobranzaPlan}
                        />
                    </div>
                    <div className="input-group">
                        <label htmlFor="clabe">Número CLABE</label>
                        <input
                            id="clabe"
                            type="text"
                            name="clabe"
                            value={bankInfo.clabe}
                            onChange={handleBankInfoChange}
                            placeholder="18 dígitos"
                            disabled={!isCobranzaPlan}
                        />
                    </div>
                    <div className="input-group">
                        <label htmlFor="numeroCuenta">Numero de cuenta</label>
                        <input
                            id="numeroCuenta"
                            type="text"
                            name="numeroCuenta"
                            value={bankInfo.numeroCuenta}
                            onChange={handleBankInfoChange}
                            placeholder="10 a 11 dígitos"
                            disabled={!isCobranzaPlan}
                        />
                    </div>
                    <div className="input-group">
                        <label htmlFor="nombreCompleto">Nombre Completo</label>
                        <input
                            id="nombreCompleto"
                            type="text"
                            name="nombreCompleto"
                            value={bankInfo.nombreCompleto}
                            onChange={handleBankInfoChange}
                            placeholder="Como aparece en tu estado de cuenta"
                            disabled={!isCobranzaPlan}
                        />
                    </div>
                </section>
            )}

            {paymentMethods.ligaPago && (
                <section className="stripe-config">
                    <h2>CONFIGURACIÓN DE LIGA DE PAGO (STRIPE)</h2>
                    <p>Agrega ligas de pago Stripe en tus recordatorios para cobros con tarjeta, OXXO o transferencia. Seguro y eficaz, con categorización automática y notificaciones inmediatas. Los fondos se reflejan en tu cuenta en 7 días.</p>
                    <p>Comisiones: 3.6% + MXN$3.00 para tarjetas nacionales y pagos en OXXO, ó MXN$7.00 por transferencia bancaria.</p>
                    
                    <button className="configure-stripe-button" onClick={handleStripeSetup} disabled={!isCobranzaPlan}>Configurar Stripe</button>
                </section>
            )}



            <section className="logica-de-pago">
                <h2>LÓGICA DE PAGO</h2>

                <div className="payment-logic-item">
                    <label>Día de pago inicial</label>
                    <div className="select-wrapper">
                        <select 
                            value={paymentLogic.paymentDay}
                            onChange={(e) => handlePaymentLogicChange('paymentDay', parseInt(e.target.value))}
                            disabled={!isCobranzaPlan}
                        >
                            {[...Array(31)].map((_, i) => (
                                <option key={i} value={i + 1}>{i + 1} de cada mes</option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="payment-logic-item">
                    <label>Días disponibles de pago</label>
                    <div className="number-input-wrapper">
                        <button onClick={() => handlePaymentLogicChange('availableDays', Math.max(1, paymentLogic.availableDays - 1))} disabled={!isCobranzaPlan}>-</button>
                        <input 
                            type="number" 
                            value={paymentLogic.availableDays}
                            onChange={(e) => handlePaymentLogicChange('availableDays', parseInt(e.target.value))}
                            min="1"
                            max="31"
                            disabled={!isCobranzaPlan}
                        />
                        <button onClick={() => handlePaymentLogicChange('availableDays', Math.min(31, paymentLogic.availableDays + 1))} disabled={!isCobranzaPlan}>+</button>
                    </div>
                </div>

                <div className="payment-logic-item">
                    <label>Penalización por pago tardío</label>
                    <div className="input-with-symbol">
                        <input 
                            type="number" 
                            value={paymentLogic.penaltyRate}
                            onChange={(e) => handlePaymentLogicChange('penaltyRate', parseFloat(e.target.value))}
                            min="0"
                            max="100"
                            step="0.1"
                            disabled={!isCobranzaPlan}
                        />
                        <span className="input-symbol">%</span>
                    </div>
                </div>
            </section>

            <div className="save-button-container">
                <button 
                    className={`save-button ${!hasChanges ? 'save-button-disabled' : ''}`}
                    onClick={handleSave}
                    disabled={!hasChanges || !isCobranzaPlan}
                >
                    Guardar cambios
                </button>
            </div>
        </div>
    );
}

export default PaymentSettings;
