import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import '../styles/DeleteRecurringEventModal.css';

const DeleteRecurringEventModal = ({ show, onHide, eventTitle, onDelete }) => {
  const [deleteOption, setDeleteOption] = useState('this');

  return (
    <Modal 
      show={show} 
      onHide={onHide} 
      centered
      className="delete-modal"
      animation={false}
    >
      <Modal.Body>
        <h4>Borrar evento que se repite</h4>
        <p>"{eventTitle}"</p>
        
        <div className="radio-group">
          <Form.Check
            type="radio"
            id="this"
            name="deleteOption"
            label="Este evento"
            checked={deleteOption === 'this'}
            onChange={() => setDeleteOption('this')}
          />
          <Form.Check
            type="radio"
            id="future"
            name="deleteOption"
            label="Este y siguientes eventos"
            checked={deleteOption === 'future'}
            onChange={() => setDeleteOption('future')}
          />
          <Form.Check
            type="radio"
            id="all"
            name="deleteOption"
            label="Todos los eventos"
            checked={deleteOption === 'all'}
            onChange={() => setDeleteOption('all')}
          />
        </div>

        <div className="button-group">
          <Button 
            className="delete-btn"
            onClick={() => onDelete(deleteOption)}
          >
            Borrar evento
          </Button>
          <Button 
            className="cancel-btn"
            onClick={onHide}
          >
            Cancelar
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteRecurringEventModal; 