import React, { createContext, useState, useContext, useEffect } from 'react';
import { onAuthStateChanged, signInWithCustomToken, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../config/firebase-config';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [communityInfo, setCommunityInfo] = useState({});
  const [activePlan, setActivePlan] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      if (user) {
        await fetchCommunityInfo(user.uid);
      } else {
        setCommunityInfo({});
        setActivePlan(null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const fetchCommunityInfo = async (userId) => {
    try {
      const communityRef = doc(db, "communities", userId);
      const communityDoc = await getDoc(communityRef);
      if (communityDoc.exists()) {
        const data = communityDoc.data();
        setCommunityInfo(data);
        setActivePlan(data.activePlan || null);
      } else {
        console.log("No community data found");
      }
    } catch (error) {
      console.error("Error fetching community info:", error);
    }
  };

  const login = async (token) => {
    try {
      console.log("Starting login process...");
      const userCredential = await signInWithCustomToken(auth, token);
      const user = userCredential.user;
      console.log("User authenticated:", user.uid);
      
      await fetchCommunityInfo(user.uid);

      return user;
    } catch (error) {
      console.error("Failed to sign in:", error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      setCurrentUser(null);
      setCommunityInfo({});
      setActivePlan(null);
    } catch (error) {
      console.error("Failed to sign out:", error);
      throw error;
    }
  };

  const value = {
    currentUser,
    login,
    logout,
    activePlan,
    communityInfo,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
