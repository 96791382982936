import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import axios from 'axios';
import './styles/PersonalInfoSettings.css';  // You'll need to create this CSS file

const PersonalInfoSettings = () => {
  const [ownerName, setOwnerName] = useState('');
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) {
        console.error('No user logged in!');
        return;
      }

      const db = getFirestore();
      const userRef = doc(db, 'users', user.uid);

      try {
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          const userData = userSnap.data();
          setOwnerName(userData.ownerName || '');
          setEmail(userData.email || '');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const auth = getAuth();
    const user = auth.currentUser;
  
    if (!user) {
      console.error('No user logged in!');
      return;
    }
  
    const db = getFirestore();
    const userRef = doc(db, 'users', user.uid);
  
    try {
      await updateDoc(userRef, {
        ownerName: ownerName
      });
      console.log('User information updated successfully');
      navigate('/profile');
    } catch (error) {
      console.error('Error updating user information:', error);
    }
  };

  return (
    <div className="personal-info-container">
      <div className="header">
        <button onClick={() => navigate(-1)} className="back-button">Volver</button>
        <h1 className="personal-info-title">Información personal</h1>
      </div>
      <form onSubmit={handleSubmit} className="personal-info-form">
        <div className="input-group">
          <label htmlFor="ownerName">Nombre</label>
          <input
            type="text"
            id="ownerName"
            value={ownerName}
            onChange={e => setOwnerName(e.target.value)}
            required
          />
        </div>

        <div className="input-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            readOnly
            className="readonly-input"
          />
        </div>

        <div className="save-button-container">
          <button type="submit" className="save-button btn btn-primary">Guardar cambios</button>
        </div>
      </form>
    </div>
  );
};

export default PersonalInfoSettings;
