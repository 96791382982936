import React from 'react';
import { Link } from 'react-router-dom';
import './styles/PaymentSuccess.css'; // Import your CSS file for styles
import CheckIcon from './icons/check.svg';


const PaymentSuccess = () => {
    return (
        <div className="success-container-wrapper">
    
            <div className="success-container">
            <div className="success-icon-container">
                <img src={CheckIcon} alt="Checkmark" className="success-icon" />      </div>
            <h2 className="success-title">¡Todo listo!</h2>
            <p className="success-message">
                Tu pago fue exitoso.
            </p>
            </div>
    
        </div>
      );
    }

export default PaymentSuccess;
