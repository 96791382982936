import React from 'react';
import './styles/Reauth.css'; // Import the regular CSS file
import { useNavigate } from 'react-router-dom';
import XIcon from './icons/x-circle.svg';

const Reauth = () => {
    const navigate = useNavigate(); 

    const handleFinalize = () => {
        navigate('/classes');
   };


  return (
    <div className="x-container-wrapper">

        <div className="x-container">
        <div className="x-icon-container">
            <img src={XIcon} alt="X" className="x-icon" />      </div>
        <h2 className="x-title">¡Oh no!</h2>
        <p className="x-message">
            Hubo un problema al crear tu cuenta, por favor, intentalo otra vez.
        </p>
        <button className="x-primary-button" onClick={handleFinalize}>Finalizar</button>
        </div>

    </div>
  );
};




export default Reauth;
