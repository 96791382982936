import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { RRule } from 'rrule';
import { getAuth } from 'firebase/auth'; 
import './styles/BaseModal.css';
import { formatInTimeZone, toDate } from 'date-fns-tz';
import { startOfDay } from 'date-fns';
import { toZonedTime, format } from 'date-fns-tz';
import { parseISO } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

const auth = getAuth(); // Add this line

const AddEventModal = ({ show, handleClose, handleSave, selectedDate, classes, activeMembers, initialData }) => {
  const [eventData, setEventData] = useState({
    event_title: '',
    start: '',
    end: '',
    classId: '',
    memberId: '',
    recurrence: 'none',
    recurrenceEndDate: '',
    eventType: 'client_appointment',
    confirmationStatus: 'pending',
    videoCall: '',
    location: '',
    allDay: false,
  });

  useEffect(() => {
    if (show) {
      const timeZone = 'America/Mexico_City';
      const now = new Date();
      const mexicoCityDate = toZonedTime(now, timeZone);
      
      const startDate = new Date(mexicoCityDate);
      startDate.setHours(9, 0, 0, 0);
      const endDate = new Date(startDate.getTime() + 60 * 60 * 1000); // 1 hour later

      const defaultRecurrenceEndDate = new Date(startDate);
      defaultRecurrenceEndDate.setMonth(defaultRecurrenceEndDate.getMonth() + 1);

      setEventData(prev => ({
        ...prev,
        start: formatDateTimeForInput(startDate, timeZone),
        end: formatDateTimeForInput(endDate, timeZone),
        recurrenceEndDate: formatDateForInput(defaultRecurrenceEndDate, timeZone)
      }));
    }
  }, [show]);

  useEffect(() => {
    if (show && selectedDate) {
      const timeZone = 'America/Mexico_City';
      setEventData(prev => ({
        ...prev,
        start: formatDateTimeForInput(selectedDate, timeZone),
        end: formatDateTimeForInput(selectedDate, timeZone)
      }));
    }
  }, [show, selectedDate]);

  useEffect(() => {
    if (show && initialData) {
      const timeZone = 'America/Mexico_City';
      setEventData({
        event_title: initialData.title || initialData.event_title || '',
        start: formatDateTimeForInput(initialData.start, timeZone),
        end: formatDateTimeForInput(initialData.end, timeZone),
        classId: initialData.classId || '',
        memberId: initialData.memberId || '',
        recurrence: initialData.recurrence || 'none',
        recurrenceEndDate: initialData.recurrenceEndDate || '',
        eventType: initialData.eventType || 'client_appointment',
        confirmationStatus: initialData.confirmationStatus || 'pending',
        videoCall: initialData.videoCall || '',
        location: initialData.location || '',
        allDay: initialData.allDay || false,
      });
    }
  }, [show, initialData]);

  // Helper function to calculate end time
  const calculateEndTime = (startTime, durationMinutes) => {
    if (!startTime || !durationMinutes) return '';
    
    try {
      const startDate = new Date(startTime);
      if (isNaN(startDate.getTime())) return ''; // Check for invalid date
      
      const endDate = new Date(startDate.getTime() + (durationMinutes * 60 * 1000));
      const timeZone = 'America/Mexico_City';
      return formatDateTimeForInput(endDate, timeZone);
    } catch (error) {
      console.error('Error calculating end time:', error);
      return '';
    }
  };

  // Effect to update end time when start time or class changes
  useEffect(() => {
    if (eventData.classId && eventData.start) {
      const selectedClass = classes.find(c => c.id === eventData.classId);
      
      if (selectedClass?.duration) {
        const newEndTime = calculateEndTime(eventData.start, selectedClass.duration);
        
        setEventData(prev => ({
          ...prev,
          end: newEndTime
        }));
      }
    }
  }, [eventData.classId, eventData.start, classes]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEventData(prev => {
      const newData = { ...prev, [name]: value };

      // If changing start time or class for a client appointment
      if (newData.eventType === 'client_appointment' && 
          (name === 'start' || name === 'classId')) {
        const selectedClass = classes.find(c => c.id === newData.classId);
        
        if (selectedClass?.duration) {
          console.log('Calculating new end time for:', {
            start: newData.start,
            duration: selectedClass.duration
          });
          
          newData.end = calculateEndTime(newData.start, selectedClass.duration);
          console.log('New end time:', newData.end);
        }
      }

      // Update title for client appointments
      if (newData.eventType === 'client_appointment' && 
          (name === 'memberId' || name === 'classId')) {
        const selectedMember = activeMembers.find(m => m.id === newData.memberId);
        const selectedClass = classes.find(c => c.id === newData.classId);
        
        if (selectedMember && selectedClass) {
          newData.event_title = `${selectedMember.contact_name} - ${selectedClass.class_name}`;
        }
      }

      return newData;
    });
  };

  const handleRecurrenceChange = (e) => {
    const { value } = e.target;
    // If it's a switch/checkbox, handle differently
    if (e.target.type === 'checkbox') {
      setEventData(prev => ({
        ...prev,
        recurrence: e.target.checked ? 'daily' : 'none' // Default to daily when enabled
      }));
    } else {
      setEventData(prev => ({ ...prev, recurrence: value }));
    }
    console.log('Recurrence changed to:', value); // Debug log
  };

  const generateRRule = () => {
    console.log('Current eventData:', eventData); // Debug log

    if (!eventData.recurrence || eventData.recurrence === 'none') {
      console.log('No recurrence, returning null');
      return null;
    }

    if (!eventData.start || !eventData.recurrenceEndDate) {
      console.log('Missing start or end date');
      return null;
    }

    const timeZone = 'America/Mexico_City';
    const startDate = toDate(parseISO(eventData.start), { timeZone });
    const endDate = toDate(parseISO(eventData.recurrenceEndDate + 'T23:59:59'), { timeZone });

    console.log('Generating RRule with:');
    console.log('Start date:', startDate);
    console.log('End date:', endDate);
    console.log('Recurrence type:', eventData.recurrence);

    const frequencyMap = {
      'daily': RRule.DAILY,
      'weekly': RRule.WEEKLY,
      'monthly': RRule.MONTHLY
    };

    const freq = frequencyMap[eventData.recurrence];
    console.log('Mapped frequency:', freq); // Debug log

    if (!freq) {
      console.error('Invalid recurrence type:', eventData.recurrence);
      return null;
    }

    const options = {
      freq: freq,
      interval: 1,
      dtstart: startDate,
      until: endDate
    };

    // Add byweekday only for weekly recurrence
    if (eventData.recurrence === 'weekly') {
      const weekdayMap = {
        0: RRule.SU,
        1: RRule.MO,
        2: RRule.TU,
        3: RRule.WE,
        4: RRule.TH,
        5: RRule.FR,
        6: RRule.SA
      };
      options.byweekday = [weekdayMap[startDate.getDay()]];
    }

    try {
      const rule = new RRule(options);
      const ruleString = rule.toString();
      console.log('Successfully generated RRule:', ruleString);
      return ruleString;
    } catch (error) {
      console.error('Error generating RRule:', error);
      return null;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Submitting with eventData:', eventData);

    // Add validation for client appointments
    if (eventData.eventType === 'client_appointment') {
      if (!eventData.memberId || !eventData.classId) {
        alert('Para citas con pacientes, debes seleccionar un paciente y un servicio.');
        return;
      }
    }
    
    const rrule = generateRRule();
    console.log('Generated rrule:', rrule);
    
    const timeZone = 'America/Mexico_City';
    const formattedEventData = {
      ...eventData,
      rrule: rrule,
      start: formatInTimeZone(new Date(eventData.start), timeZone, "yyyy-MM-dd'T'HH:mm:ssXXX"),
      end: formatInTimeZone(new Date(eventData.end), timeZone, "yyyy-MM-dd'T'HH:mm:ssXXX"),
    };
    
    handleSave(formattedEventData);
    resetForm();
    handleClose();
  };

  // Helper function to format datetime for input
  function formatDateTimeForInput(date, timeZone) {
    if (!(date instanceof Date) || isNaN(date)) {
      console.error('Invalid date:', date);
      return '';
    }
    return format(toZonedTime(date, timeZone), "yyyy-MM-dd'T'HH:mm", { timeZone });
  }

  // Add this helper function
  function formatDateForInput(date, timeZone) {
    return format(toZonedTime(date, timeZone), 'yyyy-MM-dd', { timeZone });
  }

  // Add this helper function to determine if end time should be readonly
  const isEndTimeReadOnly = () => {
    return eventData.eventType === 'client_appointment' && eventData.classId !== '';
  };

  // Add a reset function to clear the form
  const resetForm = () => {
    const timeZone = 'America/Mexico_City';
    const now = new Date();
    const mexicoCityDate = toZonedTime(now, timeZone);
    
    const startDate = new Date(mexicoCityDate);
    startDate.setHours(9, 0, 0, 0);
    const endDate = new Date(startDate.getTime() + 60 * 60 * 1000); // 1 hour later

    const defaultRecurrenceEndDate = new Date(startDate);
    defaultRecurrenceEndDate.setMonth(defaultRecurrenceEndDate.getMonth() + 1);

    setEventData({
      event_title: '',
      start: formatDateTimeForInput(startDate, timeZone),
      end: formatDateTimeForInput(endDate, timeZone),
      classId: '',
      memberId: '',
      recurrence: 'none',
      recurrenceEndDate: formatDateForInput(defaultRecurrenceEndDate, timeZone),
      eventType: 'client_appointment',
      confirmationStatus: 'pending',
      videoCall: '',
      location: '',
      allDay: false,
    });
  };

  // Modify the handleClose function
  const onModalClose = () => {
    resetForm();
    handleClose();
  };

  return (
    <Modal 
      show={show} 
      onHide={onModalClose}
      className="base-modal event-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>{initialData ? 'Editar evento' : 'Crear evento'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {/* Only show event type selector when creating a new event */}
          {!initialData && (
            <Form.Group className="form-group">
              <Form.Label>Tipo de evento</Form.Label>
              <Form.Select
                name="eventType"
                value={eventData.eventType}
                onChange={handleInputChange}
                required
              >
                <option value="client_appointment">Cita con paciente</option>
                <option value="out_of_office">Fuera de la oficina</option>
              </Form.Select>
            </Form.Group>
          )}

          {eventData.eventType === 'out_of_office' ? (
            <>
              <div className="section-label mb-2">DETALLES DEL EVENTO</div>
              
              {/* Title field for out of office */}
              <Form.Group className="mb-3">
                <Form.Label>Título</Form.Label>
                <Form.Control
                  type="text"
                  name="event_title"
                  value={eventData.event_title}
                  onChange={handleInputChange}
                  required
                  placeholder="Ingresa un título"
                />
              </Form.Group>

              {/* All Day Toggle */}
              {eventData.eventType !== 'out_of_office' && (
                <Form.Group className="mb-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <Form.Label className="mb-0">Todo el día</Form.Label>
                    <Form.Check
                      type="switch"
                      name="allDay"
                      checked={eventData.allDay}
                      onChange={(e) => handleInputChange({
                        target: {
                          name: 'allDay',
                          value: e.target.checked
                        }
                      })}
                    />
                  </div>
                </Form.Group>
              )}

              {/* Date and Time Selection */}
              <div className="time-selection mb-3">
                <div>
                  <Form.Label>Inicia</Form.Label>
                  <Form.Control
                    type={eventData.allDay ? "date" : "datetime-local"}
                    name="start"
                    value={eventData.start}
                    onChange={handleInputChange}
                    required
                    step="900"
                  />
                </div>
                <div>
                  <Form.Label>Y termina</Form.Label>
                  <Form.Control
                    type={eventData.allDay ? "date" : "datetime-local"}
                    name="end"
                    value={eventData.end}
                    onChange={handleInputChange}
                    required
                    step="900"
                    readOnly={isEndTimeReadOnly()}
                    className={isEndTimeReadOnly() ? 'readonly-input' : ''}
                  />
                </div>
              </div>

              {/* Recurrence Toggle and Options */}
              <Form.Group className="mb-3">
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Label className="mb-0">Se repite</Form.Label>
                  <Form.Check
                    type="switch"
                    name="hasRecurrence"
                    checked={eventData.recurrence !== 'none'}
                    onChange={(e) => handleRecurrenceChange({
                      target: {
                        type: 'checkbox',
                        checked: e.target.checked
                      }
                    })}
                  />
                </div>
              </Form.Group>

              {eventData.recurrence !== 'none' && (
                <>
                  <Form.Group className="mb-3">
                    <Form.Label>Cadencia</Form.Label>
                    <Form.Control
                      as="select"
                      name="recurrence"
                      value={eventData.recurrence}
                      onChange={handleRecurrenceChange}
                    >
                      <option value="daily">Diario</option>
                      <option value="weekly">Semanal</option>
                      <option value="monthly">Mensual</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Termina el:</Form.Label>
                    <Form.Control
                      type="date"
                      name="recurrenceEndDate"
                      value={eventData.recurrenceEndDate}
                      onChange={handleInputChange}
                      required={eventData.recurrence !== 'none'}
                    />
                  </Form.Group>
                </>
              )}
            </>
          ) : (
            <>
              <Form.Group className="form-group">
                <Form.Label>Estatus</Form.Label>
                <Form.Select
                  name="confirmationStatus"
                  value={eventData.confirmationStatus}
                  onChange={handleInputChange}
                  required
                >
                  <option value="confirmed">Confirmado</option>
                  <option value="pending">Pendiente de confirmación</option>
                </Form.Select>
              </Form.Group>

              <div className="details-header">PACIENTES Y SERVICIOS</div>
              
              <Form.Group className="form-group">
                <Form.Label>Paciente</Form.Label>
                <Form.Select
                  name="memberId"
                  value={eventData.memberId}
                  onChange={handleInputChange}
                  required={eventData.eventType === 'client_appointment'}
                >
                  <option value="">Selecciona un paciente...</option>
                  {activeMembers.map(member => (
                    <option key={member.id} value={member.id}>{member.contact_name}</option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className="form-group">
                <Form.Label>Servicio</Form.Label>
                <Form.Select
                  name="classId"
                  value={eventData.classId}
                  onChange={handleInputChange}
                  required={eventData.eventType === 'client_appointment'}
                >
                  <option value="">Selecciona un servicio...</option>
                  {classes.map(cls => (
                    <option key={cls.id} value={cls.id}>{cls.class_name}</option>
                  ))}
                </Form.Select>
              </Form.Group>

              <div className="details-header">DETALLES DEL EVENTO</div>

              <div className="time-selection mb-3">
                <div>
                  <Form.Label>Inicia</Form.Label>
                  <Form.Control
                    type="datetime-local"
                    name="start"
                    value={eventData.start}
                    onChange={handleInputChange}
                    required
                    step="900"
                  />
                </div>
                <div>
                  <Form.Label>Y termina</Form.Label>
                  <Form.Control
                    type="datetime-local"
                    name="end"
                    value={eventData.end}
                    onChange={handleInputChange}
                    required
                    step="900"
                    readOnly={isEndTimeReadOnly()}
                    className={isEndTimeReadOnly() ? 'readonly-input' : ''}
                  />
                </div>
              </div>

              <Form.Group className="form-group">
                <div className="d-flex justify-content-between align-items-center">
                  <Form.Label className="mb-0">Se repite</Form.Label>
                  <Form.Check
                    type="switch"
                    name="hasRecurrence"
                    checked={eventData.recurrence !== 'none'}
                    onChange={(e) => handleRecurrenceChange(e)}
                  />
                </div>
              </Form.Group>

              {eventData.recurrence !== 'none' && (
                <>
                  <Form.Group className="form-group">
                    <Form.Label>Cadencia</Form.Label>
                    <Form.Select
                      as="select"
                      name="recurrence"
                      value={eventData.recurrence}
                      onChange={handleRecurrenceChange}
                    >
                      <option value="daily">Diario</option>
                      <option value="weekly">Semanal</option>
                      <option value="monthly">Mensual</option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="form-group">
                    <Form.Label>Termina el:</Form.Label>
                    <Form.Control
                      type="date"
                      name="recurrenceEndDate"
                      value={eventData.recurrenceEndDate}
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>
                </>
              )}
            </>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button 
          className="modal-primary-btn"
          onClick={handleSubmit}
        >
          {initialData ? 'Guardar' : 'Crear'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddEventModal;

