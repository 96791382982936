import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from './contexts/AuthContexts.js';
import { loadStripe } from '@stripe/stripe-js';
import './styles/SelectPlan.css'; // Make sure to create this CSS file

// Initialize Stripe with your publishable key from environment variable
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

console.log('Stripe Key:', process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function SelectPlan() {
    const [selectedPlan, setSelectedPlan] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const { currentUser } = useAuth();
    const currentCommunity = { id: 'community-id' }; // Assuming you have access to the current community

    const plans = [
        { 
            id: 'basic', 
            name: 'Plan Básico', 
            price: 'MXN 799',
            priceDetails: '$689 MXN + IVA por mes',
            features: ['Agenda', 
                    'Modulo de seguimiento de clientes', 
                    'Catalogo de servicios', 
                    'Reportes del negocio']
        },
        { 
            id: 'cobranza', 
            name: 'Plan Cobranza', 
            price: 'MXN 1,299',
            priceDetails: '$1120 MXN + IVA por mes',
            features: [
                'Todo en el plan básico',
                'Envío de ligas de pago único y suscripción',
                'Mensajes automatizados de cobranza hasta 100 suscripciones'
            ],
            popular: true
        }
    ];

    useEffect(() => {
        console.log('Stripe Promise:', stripePromise);
        
        // Add the full-width-content class to the body
        document.body.classList.add('full-width-content');
        
        // Remove the class when the component unmounts
        return () => {
            document.body.classList.remove('full-width-content');
        };
    }, []);

    const handlePlanSelect = async () => {
        if (!selectedPlan) {
            setError('Por favor selecciona un plan');
            return;
        }

        if (!currentUser || !currentUser.uid) {
            setError('Usuario no autenticado. Por favor, inicia sesión.');
            return;
        }

        setLoading(true);
        setError('');

        try {
            const response = await axios.post('/api/stripe/create-checkout-session', {
                priceId: selectedPlan === 'basic' ? process.env.REACT_APP_STRIPE_BASIC_PRICE_ID : process.env.REACT_APP_STRIPE_COBRANZA_PRICE_ID,
                userId: currentUser.uid
            });

            const stripe = await stripePromise;

            const { error } = await stripe.redirectToCheckout({
                sessionId: response.data.sessionId
            });

            if (error) {
                setError(error.message);
            }
        } catch (error) {
            console.error('Falló la creación de la sesión de pago:', error);
            setError('Ocurrió un error al procesar el pago. Por favor intenta de nuevo.');
        } finally {
            setLoading(false);
        }
    };

    const getSelectedPlanName = () => {
        const plan = plans.find(p => p.id === selectedPlan);
        return plan ? plan.name.replace('Plan ', '') : '';
    };

    return (
        <div className="container-fluid p-0" style={{ 
            maxWidth: '800px', // Adjust this value as needed
            margin: '0 auto'
        }}>
            <div className="select-plan-container">
                <h2>Planes</h2>
                <p className="guarantee">Prueba con confianza: garantía de devolución total dentro de los primeros 30 días</p>
                {error && <div className="error-message">{error}</div>}
                <div className="plans-grid">
                    {plans.map((plan) => (
                        <div 
                            key={plan.id} 
                            className={`plan-card ${selectedPlan === plan.id ? 'selected' : ''} ${plan.popular ? 'popular' : ''}`}
                            onClick={() => setSelectedPlan(plan.id)}
                        >
                            {plan.popular && <div className="popular-tag">Más popular</div>}
                            <h3>{plan.name}</h3>
                            <div className="price">{plan.price}</div>
                            <div className="price-details">{plan.priceDetails}</div>
                            <div className="features">
                                <p>Esto incluye:</p>
                                <ul>
                                    {plan.features.map((feature, index) => (
                                        <li key={index}>{feature}</li>
                                    ))}
                                </ul>
                            </div>
                            <button className="select-button">
                                {selectedPlan === plan.id ? 'Seleccionado' : 'Elegir Plan'}
                            </button>
                        </div>
                    ))}
                </div>
                <div className="fixed-bottom-button">
                    <button 
                        className="confirm-button"
                        onClick={handlePlanSelect}
                        disabled={loading || !selectedPlan}
                    >
                        {loading ? 'Procesando...' : `Pagar plan ${getSelectedPlanName()}`}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default SelectPlan;
