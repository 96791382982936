import React from 'react';
import '../styles/EditPaymentModal.css';

const EditPaymentModal = ({ 
  isOpen, 
  onClose, 
  onSave, 
  editedStatus, 
  setEditedStatus,
  editedInvoiceStatus, 
  setEditedInvoiceStatus 
}) => {
  if (!isOpen) return null;

  return (
    <div className="edit-payment-modal-overlay">
      <div className="edit-payment-modal-content">
        <h2>Editar Estado</h2>
        
        <div className="edit-payment-modal-section">
          <label>Estado de Pago:</label>
          <select 
            value={editedStatus} 
            onChange={(e) => setEditedStatus(e.target.value)}
          >
            <option value="Pagado">Pagado</option>
            <option value="Pendiente">Pendiente</option>
          </select>
        </div>

        <div className="edit-payment-modal-section">
          <label>Estado de Factura:</label>
          <select 
            value={editedInvoiceStatus} 
            onChange={(e) => setEditedInvoiceStatus(e.target.value)}
          >
            <option value="Facturado">Facturado</option>
            <option value="Por facturar">Por facturar</option>
          </select>
        </div>

        <div className="edit-payment-modal-buttons">
          <button className="edit-payment-cancel-button" onClick={onClose}>Cancelar</button>
          <button className="edit-payment-save-button" onClick={onSave}>Guardar</button>
        </div>
      </div>
    </div>
  );
};

export default EditPaymentModal; 