import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './styles/NavBar.css';
import logo from './icons/subzy-white-wordmark.png';

import { ReactComponent as MembersIcon } from './icons/members.svg';
import { ReactComponent as CatalogIcon } from './icons/classes.svg';
import { ReactComponent as AccountIcon } from './icons/user.svg';
import { ReactComponent as PaymentsIcon } from './icons/payments.svg';
import { ReactComponent as ReportsIcon } from './icons/reports.svg';
import { ReactComponent as ScheduleIcon } from './icons/schedule.svg';
const NavBar = () => {
    const location = useLocation();

    const hiddenPaths = ['/login', '/signup', '/', '/settings/payments', '/payment-success', '/reset-password', '/select-plan'];
    
    if (hiddenPaths.includes(location.pathname)) {
        return null;
    }

    return (
        <nav className="navbar-custom">
            <div className="navbar-logo-desktop">
                <img src={logo} alt="subzy" />
            </div>

            <ul className="navbar-nav">



            <li className="nav-item">
                    <NavLink to="/schedule" className="nav-link" activeClassName="active">
                        <ScheduleIcon className="navbar-icon"/>
                        <span className='navbar-text'>Agenda</span>
                    </NavLink>
                </li>

                <li className="nav-item">
                    <NavLink to="/members" className="nav-link" activeClassName="active">
                        <MembersIcon className="navbar-icon"/>
                        <span className='navbar-text'>Clientes</span>
                    </NavLink>
                </li>


                <li className="nav-item">
                    <NavLink to="/payment-list" className="nav-link" activeClassName="active">
                        <PaymentsIcon className="navbar-icon"/>
                        <span className='navbar-text'>Pagos</span>
                    </NavLink>
                </li>




                <li className="nav-item">
                    <NavLink to="/classes" className="nav-link" activeClassName="active">
                        <CatalogIcon className="navbar-icon"/>
                        <span className='navbar-text'>Catálogo</span>
                    </NavLink>
                </li>
          
             
                 <li className="nav-item">
                    <NavLink to="/reports" className="nav-link" activeClassName="active">
                        <ReportsIcon   className="navbar-icon"/>
                        <span className='navbar-text'>Reportes</span>
                    </NavLink>
                </li> 

                  
            

                {/* <li className="nav-item">
                    <NavLink to="/profile" className="nav-link" activeClassName="active">
                        <AccountIcon className="navbar-icon"/>
                        <span className='navbar-text'>Cuenta</span>
                    </NavLink>
                </li> */}
             
            </ul>

            <div className="navbar-profile-desktop">
                <NavLink to="/profile" className="nav-link" activeClassName="active">
                    <AccountIcon className="navbar-icon"/>
                    <span className='navbar-text'>Configuración</span>
                </NavLink>
            </div>
        </nav>
    );
};

export default NavBar;