import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { auth } from './config/firebase-config';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useAuth } from './contexts/AuthContexts'; // Add this line
// Add these imports
import { db } from './config/firebase-config';
import { doc, getDoc } from 'firebase/firestore';
import subzyLogo from './subzy-logo.png';  // Add this import at the top of your file

function Login({ onLogin }) {
  const { currentUser } = useAuth(); // Now this will work
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showButtons, setShowButtons] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCheckingPlan, setIsCheckingPlan] = useState(false);

  useEffect(() => {
    const hostname = window.location.hostname;
    setShowButtons(hostname === 'localhost' || hostname.includes('beta'));
  }, []);

  useEffect(() => {
    if (currentUser) {
      navigate('/schedule');
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    document.body.classList.add('full-width-content');
    return () => {
      document.body.classList.remove('full-width-content');
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
    setIsCheckingPlan(true);
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      console.log('Login successful', userCredential.user);
      
      // Navigate to schedule page after successful login
      navigate('/schedule');
      
      /* Original plan check logic - uncomment to restore paywall
      const userDoc = await getDoc(doc(db, 'users', userCredential.user.uid));
      const userData = userDoc.data();
      
      if (userData && userData.communityId) {
        const communityDoc = await getDoc(doc(db, 'communities', userData.communityId));
        const communityData = communityDoc.data();
        
        if (communityData && communityData.activePlan) {
          navigate('/payment-list');
        } else {
          navigate('/select-plan');
        }
      } else {
        console.log('Redirecting to select-plan');
      }
      */
      
    } catch (error) {
      console.error('Login failed', error.message);
      
      // Handle specific error cases
      let errorMessage = '';
      switch (error.code) {
        case 'auth/user-not-found':
          errorMessage = 'No existe una cuenta con este correo electrónico. Por favor, regístrese primero.';
          break;
        case 'auth/wrong-password':
          errorMessage = 'Contraseña incorrecta. Por favor, inténtelo de nuevo.';
          break;
        case 'auth/invalid-email':
          errorMessage = 'El correo electrónico no es válido. Por favor, verifique e inténtelo de nuevo.';
          break;
        case 'auth/user-disabled':
          errorMessage = 'Esta cuenta ha sido deshabilitada. Por favor, contacte al soporte.';
          break;
        case 'auth/too-many-requests':
          errorMessage = 'Demasiados intentos fallidos. Por favor, inténtelo más tarde.';
          break;
        default:
          errorMessage = 'Ocurrió un error al iniciar sesión. Por favor, inténtelo de nuevo.';
      }
      setError(`${errorMessage} (Código de error: ${error.code})`);
    }
    setIsLoading(false);
    setIsCheckingPlan(false);
  };

  if (isLoading || isCheckingPlan) {
    return (
      <div className="loading-screen" style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#F4EDE4'
      }}>
        <img 
          src={subzyLogo} 
          alt="Subzy Logo" 
          style={{ 
            marginBottom: '20px',
            maxWidth: '150px',  // Adjust this value as needed
            height: 'auto'
          }} 
        />
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid p-0" style={{ 
      maxWidth: '400px', 
      margin: '0 auto'
    }}>
      <h2 className='title-login-register text-center'>Ingresa a tu cuenta</h2>
      {error && (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div className="inputgroup-register-login mb-3">
          <label htmlFor="email" className="label-login-register-form form-label">Correo electrónico</label>
          <input
            type="email"
            className="login-form-input form-control"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="inputgroup-register-login mb-3">
          <label htmlFor="password" className="label-login-register-form form-label">Contraseña</label>
          <input
            type="password"
            className="login-form-input form-control"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="text-center">
          <button type="submit" className="loginbtn btn btn-primary">Iniciar sesión</button>
        </div>
      </form>
      {showButtons && (
        <div className="mt-3 text-center">
          <p className='alt-register-login'>¿Aún no tienes cuenta? <Link to="/signup">Regístrate</Link></p>
          <p className='alt-register-login'>¿Olvidaste tu contraseña? <Link to="/reset-password">Restablece tu contraseña</Link></p>
        </div>
      )}
    </div>
  );
}

export default Login;