import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from './contexts/AuthContexts.js';
import { auth } from './config/firebase-config';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import './styles/SectionHeader.css';
import './styles/Profile.css';
import logosmall from './subzy-logo-small-neutral.png';
import chevronIcon from './icons/chevron-right.svg'; // Adjust the path as needed

function Profile() {
    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const [stripeAccountId, setStripeAccountId] = useState('');
    const [stripeLink, setStripeLink] = useState('');
    const [stripeData, setStripeData] = useState(null);
    const [userData, setUserData] = useState(null);
    const [communityData, setCommunityData] = useState(null);
    const [showButtons, setShowButtons] = useState(false);
    const { stripeOnboardingComplete } = useAuth();

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                if (!currentUser) {
                    console.error('No current user found');
                    return;
                }
                const db = getFirestore();
                const userRef = doc(db, 'users', currentUser.uid);
                const userSnap = await getDoc(userRef);

                if (!userSnap.exists()) {
                    console.error('User data not found');
                    return;
                }

                const userData = userSnap.data();
                setUserData(userData);

                if (userData.communityId) {
                    const communityRef = doc(db, 'communities', userData.communityId);
                    const communitySnap = await getDoc(communityRef);

                    if (communitySnap.exists()) {
                        setCommunityData(communitySnap.data());
                    }
                }

                console.log('Fetching Stripe account ID for user:', currentUser.uid);
                const response = await axios.post('/api/stripe/get-stripe-account-id', { uid: currentUser.uid });
                const { stripeAccountId } = response.data;
                if (!stripeAccountId) {
                    console.error('No Stripe account ID found');
                    return;
                }
                console.log('Fetched Stripe account ID:', stripeAccountId);
                setStripeAccountId(stripeAccountId);
            } catch (error) {
                console.error('Error fetching user data:', error.message);
            }
        };

        if (currentUser) {
            fetchUserData();
        }

        // Add this to determine if buttons should be shown
        const hostname = window.location.hostname;
        setShowButtons(hostname === 'localhost' || hostname.includes('beta'));
    }, [currentUser]);

    const fetchStripeData = async (accountId) => {
        try {
            if (!accountId) {
                throw new Error('No accountId provided');
            }
            console.log('Fetching Stripe data for account:', accountId);
            const response = await axios.post('/api/stripe/edit-connect-account', { accountId });
            const { url } = response.data;
            if (url) {
                window.location.href = url;  // Redirect immediately when URL is fetched
            }
        } catch (error) {
            console.error('Error fetching Stripe account data:', error.message);
        }
    };

    const handleLogout = async () => {
        try {
            await auth.signOut();
            navigate('/login');
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    const handlePlanClick = () => {
        navigate('/select-plan');  // Adjust this path if your route is different
    };

    const menuItems = [
        {
            title: 'Información personal',
            description: 'Datos básicos y de contacto',
            path: '/settings/personal'
        },
        {
            title: 'Información del negocio',
            description: 'Información general, horarios, descansos',
            path: '/onboarding'
        },
        {
            title: 'Pagos',
            description: 'Lógica y métodos de pago',
            path: '/settings/payments'
        }
    ];

    return (
        <div>
            <div className="section-header">
                <h2 className='section-title'>Cuenta</h2>
            </div>

            <div className="current-plan-short" onClick={handlePlanClick} style={{ cursor: 'pointer' }}>
                <p>Plan actual </p>
                <span></span>
                <p className='plan-tag'>{communityData?.activePlan || 'Test'}</p>
            </div>

            {/* New Menu Items */}
            {menuItems.map((item, index) => (
                <div 
                    key={index} 
                    className='menu-item' 
                    onClick={() => navigate(item.path)}
                >
                    <div className='menu-item-text'>
                        <h3>{item.title}</h3>
                        <p>{item.description}</p>
                    </div>
                    <img src={chevronIcon} alt="chevron" className="menu-item-icon" />
                </div>
            ))}

            <div className='logout-group'>
                {currentUser ? (
                    <button onClick={handleLogout} className="logoutbutton btn btn-link">Cerrar Sesión</button>
                ) : (
                    <p className="nav-item">Not authenticated</p>
                )}
            </div>

            <div className='extra-info'>
                <img className="logo-small" src={logosmall} alt="subzy" />
                <p className='footnotes'>Beta v1.0</p>
                <p className='footnotes'>2024 Subzy</p>
                <p className='footnotes'>Todos los derechos reservados</p>
            </div>

            {/* Keep the alert if you still need it */}
            {/* <div className="alert alert-warning">
                Please complete your Stripe onboarding to enable payments. 
                <button onClick={() => fetchStripeData(stripeAccountId)} className="btn btn-link">
                  Complete Onboarding
                </button>
            </div> */}
        </div>
    );
}

export default Profile;
