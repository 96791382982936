import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Toast } from 'react-bootstrap';
import { ExclamationCircle } from 'react-bootstrap-icons';
import Select from 'react-select';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import './styles/BaseModal.css';

const AddContactModal = ({ show, handleClose, saveMember, deleteMember, member, openAddPaymentModal }) => {
    const [contactName, setContactName] = useState('');
    const [number, setNumber] = useState('');
    const [email, setEmail] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [stripeCustomerId, setStripeCustomerId] = useState('');
    const [clientSubscribed, setClientSubscribed] = useState(false);
    const [activeSuscriptions, setActiveSuscriptions] = useState(null);
    const [activeSubscriptions, setActiveSubscriptions] = useState([]);
    const [localActiveSuscriptions, setLocalActiveSuscriptions] = useState([]);
    const [isFormModified, setIsFormModified] = useState(false);
    const [showWarningToast, setShowWarningToast] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const [tags, setTags] = useState('');
    const [requiresInvoice, setRequiresInvoice] = useState(false);
    const [invoiceName, setInvoiceName] = useState('');
    const [rfc, setRfc] = useState('');
    const [fiscalAddress, setFiscalAddress] = useState('');
    const [taxRegime, setTaxRegime] = useState('');
    const [cfdiUse, setCfdiUse] = useState('');
    const [availableSubscriptions, setAvailableSubscriptions] = useState([]);
    const [selectedSubscriptions, setSelectedSubscriptions] = useState([]);

    useEffect(() => {
        const fetchSubscriptions = async () => {
            const db = getFirestore();
            const classesRef = collection(db, 'classes');
            const q = query(classesRef, where("inventoryType", "==", "subscription"));
            const snapshot = await getDocs(q);
            const subscriptions = snapshot.docs.map(doc => ({
                value: doc.id,
                label: `${doc.data().class_name} - $${doc.data().cost} (${doc.data().cadence})`,
                ...doc.data()
            }));
            setAvailableSubscriptions(subscriptions);
        };

        fetchSubscriptions().catch(console.error);
    }, []);

    useEffect(() => {
        if (member) {
            setContactName(member.contact_name || '');
            setNumber(member.number || '');
            setEmail(member.email || '');
            setStripeCustomerId(member.stripeCustomerId || '');
            setClientSubscribed(member.clientSubscribed || false);
            setActiveSuscriptions(member.activeSuscriptions || null);
            setActiveSubscriptions(member.activeSubscriptions || []);
            setLocalActiveSuscriptions(member.activeSuscriptions || []);
            setIsFormModified(false);
            setTags(member.tags ? member.tags.join(', ') : '');
            setRequiresInvoice(member.requiresInvoice || false);
            setInvoiceName(member.invoiceName || '');
            setRfc(member.rfc || '');
            setFiscalAddress(member.fiscalAddress || '');
            setTaxRegime(member.taxRegime || '');
            setCfdiUse(member.cfdiUse || '');
            setSelectedSubscriptions(member.subscriptions?.map(sub => 
                availableSubscriptions.find(avSub => avSub.value === sub.id)
            ).filter(Boolean) || []);
        } else {
            setContactName('');
            setNumber('');
            setEmail('');
            setStripeCustomerId('');
            setClientSubscribed(false);
            setActiveSuscriptions(null);
            setActiveSubscriptions([]);
            setLocalActiveSuscriptions([]);
            setIsFormModified(false);
            setTags('');
            setRequiresInvoice(false);
            setInvoiceName('');
            setRfc('');
            setFiscalAddress('');
            setTaxRegime('');
            setCfdiUse('');
            setSelectedSubscriptions([]);
        }
        setFormErrors({});
    }, [member, availableSubscriptions]);

    const handleInputChange = (setter) => (e) => {
        setter(e.target.value);
        setIsFormModified(true);
        console.log('Form modified:', true); // Add this line
    };

    const handleNumberChange = (e) => {
        const value = e.target.value;
        const cleaned = value.replace(/\D/g, '');
        if (cleaned.length <= 10) {
            setNumber(cleaned);
            setIsFormModified(true);
        }
    };

    const validateForm = () => {
        const errors = {};
        if (!contactName.trim()) {
            errors.contactName = 'El nombre es requerido';
        }
        if (!number || number.length !== 10) {
            errors.number = 'Se requiere un número de celular válido de 10 dígitos';
        }
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            const tagsArray = tags
                .split(',')
                .map(tag => tag.trim())
                .filter(tag => tag !== '');
            
            saveMember({
                contact_name: contactName,
                number: number,
                email: email || null,
                status: 'Nuevo',
                clientSubscribed: localActiveSuscriptions.length > 0,
                activeSuscriptions: localActiveSuscriptions,
                tags: tagsArray,
                requiresInvoice: requiresInvoice,
                invoiceName: requiresInvoice ? invoiceName : null,
                rfc: requiresInvoice ? rfc : null,
                fiscalAddress: requiresInvoice ? fiscalAddress : null,
                taxRegime: requiresInvoice ? taxRegime : null,
                cfdiUse: requiresInvoice ? cfdiUse : null,
                subscriptions: selectedSubscriptions.map(sub => ({
                    id: sub.value,
                    className: sub.class_name,
                    cost: sub.cost,
                    cadence: sub.cadence
                })),
            });
            handleClose();
        }
    };

    const handleSaveAndCreatePayment = async () => {
        if (validateForm()) {
            try {
                const savedMember = await saveMember({
                    contact_name: contactName,
                    number: number,
                    email: email || null,
                    status: 'Nuevo',
                    clientSubscribed: member ? member.clientSubscribed : false,
                    activeSuscriptions: member ? member.activeSuscriptions : null,
                });
                console.log("Saved member data:", savedMember);
                handleClose();
                if (savedMember && typeof openAddPaymentModal === 'function') {
                    openAddPaymentModal({
                        memberId: savedMember.id,
                        memberData: savedMember
                    });
                } else {
                    console.warn('openAddPaymentModal is not a function or not provided');
                }
            } catch (error) {
                console.error('Error saving member:', error);
            }
        }
    };

    const handleDelete = () => {
        if (member && member.id) {
            deleteMember(member.id);
            handleClose();
        }
    };

    const handleRemoveSubscription = async (classId) => {
        if (!activeSuscriptions) return;

        const updatedSuscriptions = activeSuscriptions.filter(sub => sub.classId !== classId);
        setActiveSuscriptions(updatedSuscriptions);
        
        const updatedMember = {
            ...member,
            activeSuscriptions: updatedSuscriptions,
            clientSubscribed: updatedSuscriptions.length > 0
        };

        try {
            await saveMember(updatedMember);
        } catch (error) {
            console.error('Error updating subscriptions:', error);
        }
    };

    const handleUnsubscribe = (classId) => {
        setLocalActiveSuscriptions(prevSuscriptions => 
            prevSuscriptions.filter(sub => sub.classId !== classId)
        );
        setIsFormModified(true);
    };

    const handleCloseAttempt = () => {
        if (isFormModified && !showWarningToast) {
            setShowWarningToast(true);
            setIsClosing(true);
        } else {
            handleFinalClose();
        }
    };

    const handleFinalClose = () => {
        setShowWarningToast(false);
        setIsClosing(false);
        setIsFormModified(false);
        handleClose();
    };

    const handleCancelClose = () => {
        setShowWarningToast(false);
        setIsClosing(false);
    };

    useEffect(() => {
        if (showWarningToast) {
            const timer = setTimeout(() => {
                if (isClosing) {
                    handleFinalClose();
                }
            }, 5000); // 5 seconds timeout

            return () => clearTimeout(timer);
        }
    }, [showWarningToast, isClosing]);

    useEffect(() => {
        if (show) {
            setTimeout(() => {
                document.querySelector('.modal-dialog-slideout').classList.add('show');
            }, 50);
        } else {
            document.querySelector('.modal-dialog-slideout')?.classList.remove('show');
        }
    }, [show]);

    useEffect(() => {
        const adjustModalBodyPadding = () => {
            const modalBody = document.querySelector('.modal-body');
            if (modalBody) {
                const headerHeight = document.querySelector('.modal-header').offsetHeight;
                modalBody.style.paddingTop = `${headerHeight}px`;
            }
        };

        if (show) {
            adjustModalBodyPadding();
            window.addEventListener('resize', adjustModalBodyPadding);
        }

        return () => {
            window.removeEventListener('resize', adjustModalBodyPadding);
        };
    }, [show]);

    return (
        <>
            <Modal 
                show={show} 
                onHide={handleCloseAttempt}
                className="base-modal contact-modal"
                dialogClassName="modal-dialog-slideout"
                aria-labelledby="example-custom-modal-styling-title"
                fullscreen="lg-down"
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{member ? 'Editar cliente' : 'Agregar cliente'}</Modal.Title>
                </Modal.Header>

                <Modal.Body className="dynamic-padding">
                    <Form onSubmit={handleSubmit}>
                        <Form.Group>
                            <Form.Label>Nombre completo*</Form.Label>
                            <Form.Control
                                type="text"
                                value={contactName}
                                onChange={handleInputChange(setContactName)}
                                isInvalid={!!formErrors.contactName}
                                placeholder="Ingrese el nombre del cliente"
                            />
                            <Form.Control.Feedback type="invalid">
                                {formErrors.contactName}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="form-group">
                            <Form.Label>Teléfono</Form.Label>
                            <Form.Control
                                type="tel"
                                value={number}
                                onChange={handleNumberChange}
                                isInvalid={!!formErrors.number}
                                placeholder="Ingrese el número de teléfono"
                            />
                            <Form.Control.Feedback type="invalid">
                                {formErrors.number}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Email (opcional)</Form.Label>
                            <Form.Control
                                type="email"
                                value={email}
                                onChange={handleInputChange(setEmail)}
                                placeholder="Ingresa dirección de email"
                            />
                        </Form.Group>

                        <Form.Group className="mb-4">
                            <Form.Label>Etiquetas (separadas por comas)</Form.Label>
                            <Form.Control
                                type="text"
                                value={tags}
                                onChange={e => {
                                    setTags(e.target.value);
                                    setIsFormModified(true);
                                }}
                                placeholder="ej: yoga, principiantes, mañana"
                                className="rounded-input"
                            />
                        </Form.Group>

                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <span>Requiere factura</span>
                            <Form.Check
                                type="switch"
                                id="requires-invoice"
                                checked={requiresInvoice}
                                onChange={(e) => setRequiresInvoice(e.target.checked)}
                                label=""
                            />
                        </div>

                        {requiresInvoice && (
                            <div className="invoice-details">
                                <Form.Group className="mb-3">
                                    <Form.Label>Nombre o Razón Social</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={invoiceName}
                                        onChange={handleInputChange(setInvoiceName)}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>RFC</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={rfc}
                                        onChange={handleInputChange(setRfc)}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Código Postal de Domicilio Fiscal</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={fiscalAddress}
                                        onChange={handleInputChange(setFiscalAddress)}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Régimen Fiscal</Form.Label>
                                    <Form.Select
                                        value={taxRegime}
                                        onChange={(e) => setTaxRegime(e.target.value)}
                                    >
                                        <option value="">Selecciona una opción...</option>
                                        <option value="601">General de Ley Personas Morales</option>
                                        <option value="603">Personas Morales con Fines no Lucrativos</option>
                                        <option value="605">Sueldos y Salarios e Ingresos Asimilados a Salarios</option>
                                        <option value="606">Arrendamiento</option>
                                        <option value="607">Régimen de Enajenación o Adquisición de Bienes</option>
                                        <option value="608">Demás ingresos</option>
                                        <option value="609">Consolidación</option>
                                        <option value="610">Residentes en el Extranjero sin Establecimiento Permanente en México</option>
                                        <option value="611">Ingresos por Dividendos (socios y accionistas)</option>
                                        <option value="612">Personas Físicas con Actividades Empresariales y Profesionales</option>
                                        <option value="614">Ingresos por intereses</option>
                                        <option value="615">Régimen de los ingresos por obtención de premios</option>
                                        <option value="616">Sin obligaciones fiscales</option>
                                        <option value="620">Sociedades Cooperativas de Producción que optan por diferir sus ingresos</option>
                                        <option value="621">Incorporación Fiscal</option>
                                        <option value="622">Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras</option>
                                        <option value="623">Opcional para Grupos de Sociedades</option>
                                        <option value="624">Coordinados</option>
                                        <option value="625">Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas</option>
                                        <option value="626">Régimen Simplificado de Confianza</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Uso CFDI</Form.Label>
                                    <Form.Select
                                        value={cfdiUse}
                                        onChange={(e) => setCfdiUse(e.target.value)}
                                    >
                                        <option value="">Selecciona una opción...</option>
                                        <option value="G03">Gastos en general</option>
                                        <option value="D01">Honorarios médicos, dentales y gastos hospitalarios</option>
                                        <option value="P01">Por definir</option>
                                        <option value="CN01">Nómina</option>
                                        <option value="I01">Construcciones</option>
                                        <option value="I02">Mobiliario y equipo de oficina por inversiones</option>
                                        <option value="I03">Equipo de transporte</option>
                                        <option value="I04">Equipo de computo y accesorios</option>
                                        <option value="I05">Dados, troqueles, moldes, matrices y herramental</option>
                                        <option value="I08">Otra maquinaria y equipo</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                        )}

                        <Form.Group className="mb-3">
                            <Form.Label>Suscripciones</Form.Label>
                            <Select
                                isMulti
                                options={availableSubscriptions}
                                value={selectedSubscriptions}
                                onChange={(selected) => {
                                    setSelectedSubscriptions(selected || []);
                                    setIsFormModified(true);
                                }}
                                placeholder="Selecciona las suscripciones..."
                                noOptionsMessage={() => "No hay suscripciones disponibles"}
                                classNamePrefix="select"
                                formatOptionLabel={option => (
                                    <div>
                                        <strong>{option.class_name}</strong>
                                        <div>
                                            <small>
                                                ${option.cost} - {option.cadence}
                                                {option.includedEvents && ` (${option.includedEvents} clases incluidas)`}
                                            </small>
                                        </div>
                                    </div>
                                )}
                            />
                        </Form.Group>

                        {member && (
                            <Form.Group>
                                <Form.Label>Suscripciones Activas</Form.Label>
                                <div className="d-flex flex-wrap gap-2">
                                    {localActiveSuscriptions.length > 0 ? (
                                        localActiveSuscriptions.map(subscription => (
                                            <div key={subscription.classId} className="bg-light rounded-pill px-3 py-2 d-flex align-items-center">
                                                <span className="me-2">
                                                    {subscription.className} • ${subscription.cost} ({subscription.cadence})
                                                </span>
                                                <button 
                                                    type="button"
                                                    className="btn-close btn-close-sm"
                                                    onClick={() => handleUnsubscribe(subscription.classId)}
                                                    aria-label="Remove"
                                                ></button>
                                            </div>
                                        ))
                                    ) : (
                                        <p className="text-muted">No hay suscripciones activas</p>
                                    )}
                                </div>
                            </Form.Group>
                        )}
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <div className="d-flex flex-column align-items-stretch w-100">
                        <Button 
                            className="modal-primary-btn"
                            onClick={handleSubmit}
                            disabled={member && !isFormModified}
                        >
                            {member ? 'Guardar cambios' : 'Agregar cliente'}
                        </Button>
                        {member && (
                            <Button 
                                className="modal-secondary-btn"
                                variant="danger" 
                                onClick={handleDelete}
                            >
                                Eliminar
                            </Button>
                        )}
                    </div>
                </Modal.Footer>
            </Modal>

            {showWarningToast && (
                <div className="custom-toast-container">
                    <Toast
                        onClose={handleCancelClose}
                        show={showWarningToast}
                        delay={5000}
                        autohide
                        className="custom-toast warning"
                    >
                        <Toast.Body>
                            <div className="toast-content">
                                <strong>Hay cambios sin guardar.</strong>
                            </div>
                            <Button 
                                variant="link" 
                                className="toast-close-btn" 
                                onClick={handleFinalClose}
                            >
                                ×
                            </Button>
                        </Toast.Body>
                    </Toast>
                </div>
            )}
        </>
    );
};

export default AddContactModal;
