import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../config/firebase-config';
import { doc, getDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import EditPaymentModal from './EditPaymentModal';
import '../styles/PaymentDetails.css';

const PaymentDetails = () => {
  const [payment, setPayment] = useState(null);
  const [loading, setLoading] = useState(true);
  const { paymentId } = useParams();
  const navigate = useNavigate();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editedStatus, setEditedStatus] = useState('');
  const [editedInvoiceStatus, setEditedInvoiceStatus] = useState('');

  useEffect(() => {
    const fetchPaymentDetails = async () => {
      try {
        const paymentDoc = await getDoc(doc(db, 'payments', paymentId));
        if (paymentDoc.exists()) {
          setPayment({ id: paymentDoc.id, ...paymentDoc.data() });
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching payment details:', error);
        setLoading(false);
      }
    };

    fetchPaymentDetails();
  }, [paymentId]);

  const handleSaveEdit = async () => {
    try {
      const paymentRef = doc(db, 'payments', paymentId);
      await updateDoc(paymentRef, {
        status: editedStatus,
        invoiceStatus: editedInvoiceStatus,
      });
      
      setPayment(prev => ({
        ...prev,
        status: editedStatus,
        invoiceStatus: editedInvoiceStatus
      }));
      
      setIsEditModalOpen(false);
    } catch (error) {
      console.error('Error updating payment:', error);
    }
  };

  const handleEditClick = () => {
    setEditedStatus(payment.status || 'Pagado');
    setEditedInvoiceStatus(payment.invoiceStatus || 'Por facturar');
    setIsEditModalOpen(true);
  };

  const handleDelete = async () => {
    if (window.confirm('¿Estás seguro de que quieres eliminar este pago?')) {
      try {
        await deleteDoc(doc(db, 'payments', paymentId));
        navigate(-1); // Go back after successful deletion
      } catch (error) {
        console.error('Error deleting payment:', error);
      }
    }
  };

  if (loading) {
    return <div className="loading">Cargando...</div>;
  }

  if (!payment) {
    return <div className="error">Pago no encontrado</div>;
  }

  const formatCurrency = (amount) => {
    return `$${amount.toLocaleString('es-MX')} mxn`;
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return 'N/A';
    const date = timestamp.toDate();
    return date.toLocaleDateString('es-MX', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  return (
    <div className="payment-details-container">
      <div className="payment-details-header">
        <button className="back-button" onClick={() => navigate(-1)}>
          Volver
        </button>
        <div className="payment-amount">{formatCurrency(payment.totalAmount)}</div>
      </div>

      <div className="payment-details-content">
        <div className="detail-section">
          <h2>CLIENTE</h2>
          <p>{payment.clientName}</p>
        </div>

        <div className="detail-section">
          <h2>INVENTARIO</h2>
          {payment.itemsPaid?.map((item, index) => (
            <p key={index}>{item.name}</p>
          ))}
        </div>

        <div className="detail-section">
          <h2>METODO DE PAGO</h2>
          <p>{payment.paymentMethod || 'Liga de Pago'}</p>
        </div>

        <div className="detail-section">
          <h2>ESTADO</h2>
          <span className={`status-tag ${payment.status?.toLowerCase()}`}>
            {payment.status || 'Pagado'}
          </span>
        </div>

        <div className="detail-section">
          <h2>FACTURA</h2>
          <span className={`invoice-tag ${payment.invoiceStatus?.toLowerCase()}`}>
            {payment.invoiceStatus || 'Por facturar'}
          </span>
        </div> 
      </div>

      <div className="action-buttons">
        {payment.paymentMethod !== 'Liga de Pago' && (
          <button className="delete-button" onClick={handleDelete}>
            Eliminar
          </button>
        )}
        <button className="edit-button" onClick={handleEditClick}>
          Editar
        </button>
      </div>

      <EditPaymentModal 
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        onSave={handleSaveEdit}
        editedStatus={editedStatus}
        setEditedStatus={setEditedStatus}
        editedInvoiceStatus={editedInvoiceStatus}
        setEditedInvoiceStatus={setEditedInvoiceStatus}
      />
    </div>
  );
};

export default PaymentDetails;