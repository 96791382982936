import React from 'react';
import './styles/Success.css'; // Import the regular CSS file
import { useNavigate } from 'react-router-dom';
import CheckIcon from './icons/check.svg';

const Success = () => {
    const navigate = useNavigate(); 

    const handleFinalize = () => {
        navigate('/onboarding');
   };


  return (
    <div className="success-container-wrapper">

        <div className="success-container">
        <div className="success-icon-container">
            <img src={CheckIcon} alt="Checkmark" className="success-icon" />      </div>
        <h2 className="success-title">¡Todo listo!</h2>
        <p className="success-message">
            Tu cuenta fue creada con éxito. Terminar de configurar tu cuenta.
        </p>
        <button className="success-primary-button" onClick={handleFinalize}>Finalizar</button>
        </div>

    </div>
  );
};

export default Success;
