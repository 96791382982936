import React, { useState, useEffect, useRef } from 'react';
import { 
  getFirestore, 
  collection, 
  query, 
  where, 
  getDocs, 
  doc, 
  getDoc,
  updateDoc, 
  deleteDoc, 
  addDoc,
  serverTimestamp 
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import rrulePlugin from '@fullcalendar/rrule';
import AddEventModal from './AddEventModal';
import EditEventModal from './EditEventModal';
import { RRule } from 'rrule';
import { Button, ButtonGroup, Row, Col } from 'react-bootstrap';
import { format, startOfWeek, addDays, isValid, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import { formatInTimeZone, toDate } from 'date-fns-tz';
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons'; // Add this import
import { useNavigate } from 'react-router-dom';

import './styles/Schedule.css';

const db = getFirestore();
const auth = getAuth();

const spanishMonths = [
  'ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO',
  'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE'
];

export const Schedule = () => {
  const [events, setEvents] = useState([]);
  const [classes, setClasses] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [activeMembers, setActiveMembers] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date()); // Initialize with current date
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [calendarView, setCalendarView] = useState('timeGridDay');
  const calendarRef = useRef(null);
  const [selectedWeek, setSelectedWeek] = useState([]);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 992);
  const fixedBusinessHours = {
    start: '06:00',
    end: '23:00'
  };
  const navigate = useNavigate();

  useEffect(() => {
    fetchEvents();
    fetchClasses();
    fetchActiveMembers();
    updateSelectedWeek(selectedDate);
  }, []);

  useEffect(() => {
    console.log("Modal state changed:", showModal);
  }, [showModal]);

  useEffect(() => {
    const handleResize = () => {
      const newIsMobileView = window.innerWidth < 992;
      setIsMobileView(newIsMobileView);
      if (newIsMobileView && calendarRef.current) {
        const calendarApi = calendarRef.current.getApi();
        calendarApi.changeView('timeGridDay');
        setCalendarView('timeGridDay');
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call once to set initial state
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchEvents = async () => {
    const user = auth.currentUser;
    if (!user) return;

    try {
      const eventsRef = collection(db, 'events');
      const q = query(eventsRef, where('createdBy', '==', user.uid));
      const querySnapshot = await getDocs(q);

      const fetchedEvents = [];

      querySnapshot.forEach((doc) => {
        const event = { id: doc.id, ...doc.data() };
        console.log('Raw event from Firestore:', event);
        
        if (event.rrule) {
          // For recurring events
          const [dtstartPart, rrulePart] = event.rrule.split('\nRRULE:');
          
          // Get the original start date
          const startDate = new Date(event.start);
          const endDate = new Date(event.end);
          
          console.log('Processing recurring event:', {
            originalStart: event.start,
            parsedStart: startDate,
            startHours: startDate.getUTCHours(),
            startMinutes: startDate.getUTCMinutes()
          });

          const recurringEvent = {
            id: doc.id,
            title: event.title || event.class_name,
            // Use the original start time for the first occurrence
            startRecur: startDate,
            // Add the time information to the RRULE
            rrule: `DTSTART:${event.start.replace(/[-:]/g, '').replace('.000Z', 'Z')}\nRRULE:${rrulePart}`,
            duration: {
              hours: (endDate - startDate) / (1000 * 60 * 60)
            },
            extendedProps: {
              ...event,
              confirmationStatuses: event.confirmationStatuses || {},
              isRecurring: true
            }
          };
          
          console.log('Created recurring event object:', recurringEvent);
          fetchedEvents.push(recurringEvent);
        } else {
          // Non-recurring events remain the same
          fetchedEvents.push({
            id: doc.id,
            title: event.title || event.class_name,
            start: event.start,
            end: event.end,
            extendedProps: {
              ...event,
              isRecurring: false
            }
          });
        }
      });

      console.log('Final events array:', fetchedEvents);
      setEvents(fetchedEvents);
    } catch (error) {
      console.error('Error in fetchEvents:', error);
    }
  };

  const fetchClasses = async () => {
    const user = auth.currentUser;
    if (!user) return;

    try {
      const q = query(collection(db, 'classes'), where('createdBy', '==', user.uid));
      const querySnapshot = await getDocs(q);
      const classesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      console.log('Fetched classes:', classesData);
      setClasses(classesData);
    } catch (error) {
      console.error('Error fetching classes:', error);
    }
  };

  const fetchActiveMembers = async () => {
    const user = auth.currentUser;
    if (!user) return;

    try {
      const q = query(
        collection(db, 'members'), 
        where('createdBy', '==', user.uid),
        where('active_status', '==', 'active')
      );
      const querySnapshot = await getDocs(q);
      const membersData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setActiveMembers(membersData);
    } catch (error) {
      console.error('Error fetching active members:', error);
    } 
  };

  const handleAddEvent = () => {
    setShowModal(true);
    
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSaveEvent = async (eventData) => {
    console.log("handleSaveEvent called with data:", eventData);
    const user = auth.currentUser;
    if (!user) {
      console.log("No user logged in");
      return;
    }

    try {
      const selectedClass = classes.find(c => c.id === eventData.classId);
      const selectedMember = activeMembers.find(m => m.id === eventData.memberId);

      // Parse the start and end dates
      const startDate = new Date(eventData.start);
      const endDate = new Date(eventData.end);

      const eventToSave = {
        title: eventData.event_title,
        classId: eventData.classId,
        class_name: selectedClass ? selectedClass.class_name : '',
        inventoryType: selectedClass ? selectedClass.inventoryType : 'default',
        memberId: eventData.memberId,
        memberName: selectedMember ? selectedMember.contact_name : '',
        start: startDate.toISOString(),
        end: endDate.toISOString(),
        createdAt: serverTimestamp(),
        createdBy: user.uid,
        activeStatus: 'active',
        eventType: eventData.eventType,
        confirmationStatus: eventData.confirmationStatus,
        videoCall: eventData.videoCall || '',
        location: eventData.location || ''
      };

      if (eventData.rrule) {
        eventToSave.rrule = eventData.rrule;
      }

      if (user.communityId) {
        eventToSave.communityId = user.communityId;
      }

      await addDoc(collection(db, 'events'), eventToSave);
      fetchEvents(); // Refresh the events list
      setShowModal(false);
    } catch (error) {
      console.error('Error saving event:', error);
    }
  };

  const spanishDays = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];

  const getSpanishMonth = (date) => {
    return spanishMonths[date.getMonth()];
  };

  const formatDate = (date) => {
    return date.toISOString().split('T')[0];
  };

  const getWeekDates = (date) => {
    const curr = new Date(date);
    const first = curr.getDate() - curr.getDay() + 1;
    const week = [];
    for (let i = 0; i < 7; i++) {
      const nextDay = new Date(curr.setDate(first + i));
      week.push(nextDay);
    }
    return week;
  };

  const weekDates = getWeekDates(selectedDate);

  const today = new Date();

  const isToday = (date) => {
    const today = new Date();
    return date.getDate() === today.getDate() &&
           date.getMonth() === today.getMonth() &&
           date.getFullYear() === today.getFullYear();
  };

  const customButtons = {
    myTodayButton: {
      text: 'Today',
      click: () => {
        const calendarApi = calendarRef.current.getApi();
        calendarApi.today();
      }
    }
  };

  const handleDateClick = (arg) => {
    setSelectedDate(arg.date);
    setShowModal(true);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const renderEventContent = (eventInfo) => {
    const { event } = eventInfo;
    const isOutOfOffice = event.extendedProps.eventType === 'out_of_office';
    
    return (
      <div className={`event-content ${isOutOfOffice ? 'out-of-office-event' : 'client-event'}`}>
        <div className="event-title">{event.title}</div>
        <div className="event-time">
          {eventInfo.timeText}
        </div>
      </div>
    );
  };

  const handleEventClick = (clickInfo) => {
    console.log('Event clicked:', clickInfo.event); // Debug log
    const eventId = clickInfo.event.id;
    console.log('Navigating to event ID:', eventId); // Debug log
    navigate(`/events/${eventId}`);
  };

  const handleEventEdit = async (updatedEventData) => {
    try {
      const eventRef = doc(db, 'events', updatedEventData.id);
      await updateDoc(eventRef, {
        event_title: updatedEventData.event_title,
        start: updatedEventData.start.toISOString(),
        end: updatedEventData.end.toISOString(),
        classId: updatedEventData.classId,
        class_name: updatedEventData.class_name,
        memberId: updatedEventData.memberId,
        memberName: updatedEventData.memberName,
        // Add any other fields you want to update
      });
      await fetchEvents(); // Refresh events
      setShowEditModal(false);
    } catch (error) {
      console.error('Error updating event:', error);
    }
  };

  const handleDeleteEvent = async (eventId, deleteOption) => {
    if (!auth.currentUser) return;

    try {
      let originalEventId = eventId;
      let occurrenceDate = null;

      if (eventId.includes('_')) {
        [originalEventId, occurrenceDate] = eventId.split('_');
        occurrenceDate = new Date(parseInt(occurrenceDate));
      }

      const eventRef = doc(db, 'events', originalEventId);
      const eventDoc = await getDoc(eventRef);

      if (!eventDoc.exists()) return;

      const eventData = eventDoc.data();
      
      if (eventData.rrule) {
        const [dtstartStr, rruleStr] = eventData.rrule.split('\nRRULE:');
        
        switch (deleteOption) {
          case 'this':
            const exdates = eventData.exdates || [];
            const newExdate = occurrenceDate || new Date(eventData.start);
            await updateDoc(eventRef, {
              exdates: [...exdates, newExdate.toISOString()]
            });
            break;

          case 'future':
            if (occurrenceDate || eventData.start) {
              const untilDate = occurrenceDate || new Date(eventData.start);
              // Format the UNTIL date in the correct format (YYYYMMDDTHHMMSSZ)
              const untilStr = untilDate.toISOString()
                .replace(/[-:]/g, '')
                .replace(/\.\d+Z$/, 'Z');
              
              // Modify the existing RRULE string to add/update UNTIL
              const updatedRruleStr = rruleStr.includes('UNTIL=')
                ? rruleStr.replace(/UNTIL=[^;]+/, `UNTIL=${untilStr}`)
                : `${rruleStr};UNTIL=${untilStr}`;
              
              await updateDoc(eventRef, {
                rrule: `${dtstartStr}\nRRULE:${updatedRruleStr}`
              });
            }
            break;

          case 'all':
            // Delete the entire series
            await deleteDoc(eventRef);
            break;
        }
      } else {
        // Handle non-recurring event deletion
        await deleteDoc(eventRef);
      }

      await fetchEvents();
    } catch (error) {
      console.error("Error deleting event: ", error);
    }
  };

  const handleViewChange = (view) => {
    setCalendarView(view);
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.changeView(view);
    }
  };

  const renderDayHeader = (date) => {
    if (calendarView === 'timeGridWeek') {
      return ''; // Return an empty string to hide the day names in week view
    }
    const dayNames = ['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'];
    return dayNames[date.weekday === 0 ? 6 : date.weekday - 1]; // Adjust for Monday as first day
  };

  const renderWeekNumbers = ({ date }) => {
    const startOfWeek = new Date(date.marker);
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay() + 1);
    const weekDates = [];
    for (let i = 0; i < 7; i++) {
      const currentDate = new Date(startOfWeek);
      currentDate.setDate(startOfWeek.getDate() + i);
      weekDates.push(currentDate.getDate());
    }
    return (
      <div className="week-numbers">
        {weekDates.map((day, index) => (
          <span key={index} className="week-number">{day}</span>
        ))}
      </div>
    );
  };

  const updateSelectedWeek = (date) => {
    if (!isValid(date)) {
      date = new Date(); // Use current date if invalid
    }
    const startDate = startOfWeek(date, { weekStartsOn: 1 }); // Start week on Monday
    const weekDays = Array.from({ length: 7 }, (_, i) => addDays(startDate, i));
    setSelectedWeek(weekDays);
  };

  const handleDateSelect = (date) => {
    if (!isValid(date)) {
      date = new Date(); // Use current date if invalid
    }
    setSelectedDate(date);
    updateSelectedWeek(date);
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(date);
      if (window.innerWidth < 992) { // Check if it's mobile view
        calendarApi.changeView('timeGridDay');
        setCalendarView('timeGridDay');
      }
    }
  };

  const WeekDatePicker = () => {
    const currentMonth = format(selectedDate, 'MMMM', { locale: es });
    
    const formatWeekdayShort = (date) => {
      return format(date, 'EEE', { locale: es }).slice(0, 3).toUpperCase();
    };

    const navigateWeek = (direction) => {
      const newDate = addDays(selectedDate, direction === 'next' ? 7 : -7);
      handleDateSelect(newDate);
    };

    const goToToday = () => {
      handleDateSelect(new Date());
    };

    const getWeekRange = () => {
      // Get Monday (start) of current week
      const monday = startOfWeek(selectedDate, { weekStartsOn: 1 });
      // Get Sunday (end) of current week
      const sunday = addDays(monday, 6);
      
      // Format as "Semana del 9 al 15"
      return `Semana del ${format(monday, 'd')} al ${format(sunday, 'd')}`;
    };

    return (
      <div className="week-date-picker">
        <div className="month-header-container">
          <h2 className="month-header">{currentMonth}</h2>
          <Button 
            variant="outline-dark" 
            className="today-button" 
            onClick={goToToday}
          >
            Hoy
          </Button>
          
          {!isMobileView && (
            <>
              <div className="view-toggle">
                <ButtonGroup>
                  <Button
                    variant={calendarView === 'timeGridDay' ? 'primary' : 'secondary'}
                    onClick={() => handleViewChange('timeGridDay')}
                  >
                    Día
                  </Button>
                  <Button
                    variant={calendarView === 'timeGridWeek' ? 'primary' : 'secondary'}
                    onClick={() => handleViewChange('timeGridWeek')}
                  >
                    Semana
                  </Button>
                </ButtonGroup>
              </div>
              {calendarView === 'timeGridWeek' && (
                <div className="desktop-week-nav">
                  <button 
                    className="week-nav-button-desktop" 
                    onClick={() => navigateWeek('prev')}
                  >
                    <ChevronLeft />
                  </button>
                  <span className="week-range">{getWeekRange()}</span>
                  <button 
                    className="week-nav-button-desktop" 
                    onClick={() => navigateWeek('next')}
                  >
                    <ChevronRight />
                  </button>
                </div>
              )}
            </>
          )}
        </div>
        {calendarView === 'timeGridDay' && (
          <div className="days-container">
            <button className="week-nav-button" onClick={() => navigateWeek('prev')}>
              <ChevronLeft />
            </button>
            {selectedWeek.map((day, index) => {
              const isSelected = isValid(selectedDate) && format(day, 'yyyy-MM-dd') === format(selectedDate, 'yyyy-MM-dd');
              const isToday = format(day, 'yyyy-MM-dd') === format(new Date(), 'yyyy-MM-dd');
              return (
                <div 
                  key={index} 
                  className={`day-item ${isSelected ? 'selected' : ''} ${isToday ? 'today' : ''}`}
                  onClick={() => handleDateSelect(day)}
                >
                  <span className="day-name">{formatWeekdayShort(day)}</span>
                  <span className="day-number">{format(day, 'd')}</span>
                </div>
              );
            })}
            <button className="week-nav-button" onClick={() => navigateWeek('next')}>
              <ChevronRight />
            </button>
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    console.log('Current events state:', events);
  }, [events]);

  return (
    <div className="schedule-page">
      <WeekDatePicker />
      <div className="calendar-container">
        <FullCalendar
          ref={calendarRef}
          plugins={[timeGridPlugin, interactionPlugin, rrulePlugin]}
          initialView={isMobileView ? 'timeGridDay' : calendarView}
          headerToolbar={false}
          height="auto"
          allDaySlot={false}
          slotDuration="00:30:00"
          slotLabelInterval="01:00"
          slotLabelFormat={{
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
          }}
          dayHeaderFormat={{ weekday: 'long', month: 'numeric', day: 'numeric', omitCommas: true }}
          locale="es"
          weekNumbers={false}
          weekText=""
          className="custom-calendar"
          initialDate={selectedDate}
          events={events}
          contentHeight="auto"
          expandRows={true}
          eventContent={renderEventContent}
          eventClick={handleEventClick}
          views={{
            timeGridDay: {
              type: 'timeGrid',
              duration: { days: 1 }
            },
            timeGridWeek: {
              type: 'timeGrid',
              duration: { weeks: 1 }
            }
          }}
          slotMinTime={fixedBusinessHours.start}
          slotMaxTime={fixedBusinessHours.end}
          eventDidMount={(info) => {
            const event = info.event;
            const isOutOfOffice = event.extendedProps.eventType === 'out_of_office';
            
            // Check confirmation status for both regular and recurring events
            let isConfirmed = false;
            if (event.extendedProps.rrule) {
                // For recurring events, check the specific instance date
                const instanceDate = event.start.toISOString();
                isConfirmed = event.extendedProps.confirmationStatuses?.[instanceDate] === 'confirmed';
            } else {
                isConfirmed = event.extendedProps.confirmationStatus === 'confirmed';
            }
            
            const isPending = !isConfirmed;
            
            if (isOutOfOffice) {
                info.el.classList.add('out-of-office-event');
            } else if (isPending) {
                info.el.classList.remove('fc-timegrid-event');
                info.el.classList.add('pending-confirmation');
                
                const harness = info.el.closest('.fc-timegrid-event-harness');
                if (harness) {
                    harness.style.height = '100%';
                }
            } else if (isConfirmed) {
                info.el.classList.add('confirmed-event');
            }
          }}
          dayHeaderContent={({ date }) => {
            if (calendarView === 'timeGridWeek') {
              return (
                <>
                  <div>{format(date, 'EEE', { locale: es })}</div>
                  <div>{format(date, 'd')}</div>
                </>
              );
            }
            return null;
          }}
          timeZone='local'
          eventTimeFormat={{
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
          }}
          firstDay={1}
          slotMinTime="07:00:00"
          slotMaxTime="21:00:00"
          weekends={true}
          hiddenDays={[]}
          dayHeaderFormat={{ 
            weekday: 'long'
          }}
        />
      </div>
      <div className="fab-container">
        <button className="fab" onClick={() => setShowModal(true)}>+</button>
      </div>
      <AddEventModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        handleSave={handleSaveEvent}
        selectedDate={selectedDate}
        classes={classes}
        activeMembers={activeMembers}
      />
      <EditEventModal
        show={showEditModal}
        handleClose={() => setShowEditModal(false)}
        handleDelete={handleDeleteEvent}
        event={selectedEvent}
      />
    </div>
  );
};

export default Schedule;


